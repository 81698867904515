<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Actualités</h2>
            <p>Nos plus récentes nouvelles</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="assets/img/blog-image/blog5.jpg" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posté le : </span> <a routerLink="/">15 Août 2022</a></li>
                            </ul>
                        </div>
                        <h3>Trinity Finance, experts en change et paiement lance bientot sa plateforme Trinity Change.</h3>
                        <p>Trinity Finance, experts en change et paiement lance bientôt sa plateforme Trinity Change .</p>
                    </div>

                </div>

            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">

                    <div class="widget widget_klob_posts_thumb">
                        <h3 class="widget-title">Nouvelles récentes</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">01 Août 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog">Trinity Finance, experts en change et paiement lance bientôt sa plateforme Trinity Change .</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">08 Août 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog">Comment être informe des derniers changement de taux de votre bureau de change .</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">15 Août 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog">Comment simuler une opération de change.</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>


                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->

<app-account-button></app-account-button>
