<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <p>{{'equipe' | translate}}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{'ecrvnous' | translate}}</h2>
            <div class="bar"></div>
            <p>{{'contact_text' | translate}}</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>{{'adrr' | translate}}</span>
                            Cotonou, Carrefour Missèbo, en face de l'imprimerie L.D OUANOU
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>{{'maill' | translate}}</span>
                            <a href="mailto:info@trinitycfx.com">info@trinitycfx.com</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>{{'phone' | translate}}</span>
                            <a href="tel:+22961185747">+229  61  18  57  47</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form (submit)="sendEmail()" id="contactForm" novalidate>
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                              <input type="text" name="name" id="name" class="form-control" placeholder="Nom" [(ngModel)]="name" required (blur)="onNameBlur()">
                              <span class="error-message" *ngIf="!name && nameTouched">Le nom est requis.</span>
                            </div>
                          </div>
                          <div class="form-group mb-3">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email" [(ngModel)]="email" required (blur)="onEmailBlur()">
                            <span class="error-message" *ngIf="(!email || !emailIsValid()) && emailTouched">L'adresse e-mail est requise et doit être valide.</span>
                          </div>
                          <div class="form-group mb-3">
                            <input type="text" name="phone_number" id="phone_number" required data-error="Entrer votre numero de telephone" class="form-control" placeholder="Telephone" [(ngModel)]="phone_number" required pattern="[0-9]+" (blur)="onPhoneBlur()">
                            <span class="error-message" *ngIf="(!phone_number || !phone_numberIsValid()) && phone_numberTouched">Le numéro de téléphone est requis et doit contenir uniquement des chiffres.</span>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                              <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder=" {{'Objet'| translate}}" [(ngModel)]="msg_subject" required (blur)="onMsgSubjectBlur()">
                              <span class="error-message" *ngIf="!msg_subject && msg_subjectTouched">Le sujet du message est requis.</span>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                              <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Votre Message" [(ngModel)]="message" required (blur)="onMessageBlur()"></textarea>
                              <span class="error-message" *ngIf="!message && messageTouched">Le message est requis.</span>
                            </div>
                          </div>
                          <div *ngIf="emailSent" class="success-message">
                            E-mail envoyé avec succès !
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">{{'btn_contact' | translate}}</button>
                          </div>
                        </div>
                      </form>
                      
                </div>
            </div>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</div>

<app-account-button></app-account-button>
