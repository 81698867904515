import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';

@Component({
  selector: 'app-auth-verification',
  templateUrl: './auth-verification.component.html',
  styleUrls: ['./auth-verification.component.scss']
})
export class AuthVerificationComponent implements OnInit {

  isLoading: boolean = false;
  message : string = "545151";
  responseName : string ="";
  responseSurname : string ="";
  errorMessage:string;
  loaded: boolean = false;

  constructor(private trinityRepo: TrinityRepository,private route : ActivatedRoute,) { 
    this.route.data.subscribe(routeResponse => {
      let params = this.route.snapshot.params['slug'];
      this.message = params;
      //this.ngxService.start();
    });
    
  }

  ngOnInit(): void {
    this.saveVerification();
  }

  saveVerification(){
    this.trinityRepo.verifyAccount(this.message).then((result:any) => { 
      console.log('fgvftb');
      console.log(result)
      let data = result.data;
      this.responseName = data.nom;
      this.responseSurname = data.prenom;
     
      if (result === undefined || result === null  ) {
        let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
        this.errorMessage = error,
        console.log(error)
      }
      if (result=== "error") {
        let error: string = result.message;
        console.log(error)
        return;
      } else {
      }
    }, (error: any) => {
      let errorMsg: string = error.message;
      console.log(error)
      return;

    })
  }

}
