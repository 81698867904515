import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {



  constructor(private http: HttpClient) { }


    //enregistre les taux configures
    public saveCurrentRates(obj: any[]): void {
      let inter: any = {data: obj};
      this.saveToSession("CURRENT_RATE_TRINITY", inter);
    };
     //retourne les taux configures
     public getCurrentRates(): any[] {
       let inter = this.readFromSession("CURRENT_RATE_TRINITY");
      return inter.data;
    };
    

    //enregistre un taux 
    public saveTauxMoyens(obj: any[]): void {
      let inter: any = {data: obj};
      this.saveToSession("TAUX_MOYENS_TRINITY", inter);
    };
     //retourne une destination 
     public getTauxMoyens(): any[] {
       let inter = this.readFromSession("TAUX_MOYENS_TRINITY");
      return inter.data;
    };

     //enregistre un utiulisateur 
     public saveUserConnected(obj: any): void {
      this.saveToSession("USER_LOG_TRINITY", obj);
    };
     //retourne un utilisateur 
     public getUserConnected(): any {
      return this.readFromSession("USER_LOG_TRINITY");
    };
    
     //enregistre une liste de devise 
     public saveListDevise(obj: any): void {
      this.saveToSession("DEVISE_TRINITY", obj);
    };
     //retourne un utilisateur 
     public getListDevise(): any {
      return this.readFromSession("DEVISE_TRINITY");
    };
    
     //enregistre une line de taux 
     public saveRateLine(obj: any): void {
      this.saveToSession("SELECTED_RATE_LINE_TRINITY", obj);
    };
     //retourne une ligne de taux 
     public getRateline(): any {
      return this.readFromSession("SELECTED_RATE_LINE_TRINITY");
    };
     //enregistre une line de taux 
     public saveSelectedLanguage(obj: any): void {
      this.saveToSession("SELECTED_LANGUAGE_TRINITY", obj);
    };
     //retourne une ligne de taux 
     public getSelectedLanguage(): any {
      return this.readFromSession("SELECTED_LANGUAGE_TRINITY");
    };

  private saveToSession(key: string, value: any): void {
    var stringified = unescape(encodeURIComponent(JSON.stringify(value)));
    var jsonValue = btoa(stringified);
    localStorage.setItem(key, jsonValue);
  };

  private readFromSession(key: any): any {
      let result = null; let decoded: string = "";
      try {
        let json:any = localStorage.getItem(key);
        decoded = decodeURIComponent(escape(atob(json)));
        result = JSON.parse(decoded); 
      } catch (e) {
        result = decoded;
      }
      return result;
  };

}

