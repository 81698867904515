import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { I18nServiceService } from './shared/services/utils/i18n-service.service';
import { LocalStorageService } from './shared/services/utils/localstorage.service';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    currentLang='fr';

    languages=[
        {name:'French',lang:'fr'},
        {name:'English',lang:'en'}
    ];

    constructor(private router: Router,private translate: TranslateService,private i18nService: I18nServiceService,
        private localStorageservice: LocalStorageService
        ) {

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.addLangs(['en','fr'])
        translate.setDefaultLang('fr');

        const browserLang=translate.getBrowserLang();
        this.localStorageservice.saveSelectedLanguage(browserLang);
        // the lang to use, if the lang isn't available, it will use the current loader to get them

         let defaultLang: string = 'fr'; //  browserLang.match(/en|fr/)?browserLang:'fr';
         
         let saveLang = localStorage.getItem('lang');
        if (saveLang === undefined || saveLang === null ){
            //translate.use('fr');
            translate.setDefaultLang('fr');
        }else{
            translate.use(saveLang);
        }
         console.log(saveLang)
         //translate.use(saveLang);

        //translate.use(saveLang);

    }
    onLangChange(currentLang:string){
        this.translate.use(currentLang);
    }


    ngOnInit() {
        this.recallJsFuntions();
       // throw new Error('Sentry Test Error')
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationStart ) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader').fadeOut('slow');
               // this.location = this.router.url;
                // /console.log(location);
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }


    
}
