
  import { Injectable } from '@angular/core';
import { ApiCenter } from '../api/api.center';
import { LocalStorageService } from '../services/utils/localstorage.service';
  

@Injectable()
export class TrinityRepository {
  constructor(private apiCenter: ApiCenter, private _storageService: LocalStorageService) {}

  //get
  sendNotif(item:any) {
    try {
      return this.apiCenter.sendNotification(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getPAysList( item: any) {
    try {
      return this.apiCenter.getPays(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  async getRates(item:any)  {
    try {
      let result: any = await this.apiCenter.getRate(item);
      if(result.status === "error"){
        throw new Error(result.message);
      }
      this._storageService.saveCurrentRates(result.data);
      //console.log("je viens d'enregistrer les taux");
      return result.data;
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
  }//end 

  //get
  getChangedRates(item:any) {
    try {
      return this.apiCenter.getChangeRates(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getContact(item:any) {
    try {
      return this.apiCenter.contactUS(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getSimulation(item:any) {
    try {
      return this.apiCenter.simulateOpChange(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getBookedTransaction(item:any) {
    try {
      return this.apiCenter.bookTransaction(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //login 
  login(item:any) {
    try {
      return this.apiCenter.logIn(item);
    } catch (error: any) {
      throw new Error(error.message);
    }
   }//end login

   //register 
  register(item:any) {
    try {
      return this.apiCenter.register(item);
    } catch (error: any) {
      throw new Error(error.message);
    }
   }//end 

  //get
  registerNewsletter(item:any) {
    try {
      return this.apiCenter.newsletterRegister(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getbookingValidity(item:any) {
    try {
      return this.apiCenter.bookingValidity(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  emailValidity(item:any) {
    try {
      return this.apiCenter.confirmEmail(item);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  getBlog() {
    try {
      return this.apiCenter.blog();
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 

  //get
  verifyAccount(token) {
    try {
      return this.apiCenter.saveVerification(token);
    } catch (error: any) {
      console.log(error)
     if (error.ok === true){
      throw new Error(error.message);
     }
      throw new Error(error.message);
    }
   
  }//end 


   
 




}