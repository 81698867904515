import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCenter } from 'src/app/shared/api/api.center';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import { LocalStorageService } from 'src/app/shared/services/utils/localstorage.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public register = {
    password: '',
     email: '',
   }

  constructor(
    private http: HttpClient, private localStorageService: LocalStorageService,
     private router: Router,private trinityRepo: TrinityRepository, private apiCenter: ApiCenter, 
     ) { }

  ngOnInit() {
    this.getChangingRate()
  }
  

  async getChangingRate(){
    
    console.log(this.register);
    this.trinityRepo.getBlog();
  }
}
