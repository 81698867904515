import {
    HttpClient,
    HttpHeaders,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
//import { resolvePtr } from 'dns';
  
  import { Observable,firstValueFrom } from 'rxjs';
  import { catchError } from 'rxjs/operators';
import { ApiConfig } from './app.config';
  

  
  @Injectable()
  export class ApiCenter {
  
    constructor(private http: HttpClient, private appService: ApiConfig) {
        this._baseUrl = this.appService.getBaseUrl();
        this.payUrl = this.appService.getPaysUrl();
     //console.log(this._baseUrl)
     }

    private  _baseUrl: String = "";
    private  payUrl: String = "";
  //   private testApiResponse:any = {
  //     "status": "success",
  //     "data": [
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 500,
  //             "prixAchat": 665.000,
  //             "prixVente": 701.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 200,
  //             "prixAchat": 688.000,
  //             "prixVente": 701.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 100,
  //             "prixAchat": 686.000,
  //             "prixVente": 699.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 50,
  //             "prixAchat": 686.000,
  //             "prixVente": 699.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 20,
  //             "prixAchat": 667.000,
  //             "prixVente": 694.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 10,
  //             "prixAchat": 667.000,
  //             "prixVente": 694.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 5,
  //             "prixAchat": 667.000,
  //             "prixVente": 694.000
  //         },
  //         {
  //             "nomDevise": "EUR",
  //             "nomCoupure": 1,
  //             "prixAchat": 667.000,
  //             "prixVente": 694.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 100,
  //             "prixAchat": 628.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 50,
  //             "prixAchat": 625.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 20,
  //             "prixAchat": 550.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 10,
  //             "prixAchat": 550.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 5,
  //             "prixAchat": 400.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "DOL",
  //             "nomCoupure": 1,
  //             "prixAchat": 400.000,
  //             "prixVente": 639.000
  //         },
  //         {
  //             "nomDevise": "GBP",
  //             "nomCoupure": 50,
  //             "prixAchat": 755.000,
  //             "prixVente": 795.000
  //         },
  //         {
  //             "nomDevise": "GBP",
  //             "nomCoupure": 20,
  //             "prixAchat": 735.000,
  //             "prixVente": 795.000
  //         },
  //         {
  //             "nomDevise": "GBP",
  //             "nomCoupure": 10,
  //             "prixAchat": 685.000,
  //             "prixVente": 795.000
  //         },
  //         {
  //             "nomDevise": "GBP",
  //             "nomCoupure": 5,
  //             "prixAchat": 685.000,
  //             "prixVente": 795.000
  //         },
  //         {
  //             "nomDevise": "XAF",
  //             "nomCoupure": 10000,
  //             "prixAchat": 0.850,
  //             "prixVente": 1.000
  //         },
  //         {
  //             "nomDevise": "XAF",
  //             "nomCoupure": 5000,
  //             "prixAchat": 0.850,
  //             "prixVente": 1.000
  //         },
  //         {
  //             "nomDevise": "XAF",
  //             "nomCoupure": 1000,
  //             "prixAchat": 0.850,
  //             "prixVente": 1.000
  //         },
  //         {
  //             "nomDevise": "XAF",
  //             "nomCoupure": 500,
  //             "prixAchat": 0.850,
  //             "prixVente": 1.000
  //         },
  //         {
  //             "nomDevise": "XAF",
  //             "nomCoupure": 2000,
  //             "prixAchat": 0.850,
  //             "prixVente": 1.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 1000,
  //             "prixAchat": 655.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 200,
  //             "prixAchat": 635.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 100,
  //             "prixAchat": 620.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 50,
  //             "prixAchat": 595.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 20,
  //             "prixAchat": 500.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 10,
  //             "prixAchat": 500.000,
  //             "prixVente": 695.000
  //         },
  //         {
  //             "nomDevise": "CHF",
  //             "nomCoupure": 5,
  //             "prixAchat": 500.000,
  //             "prixVente": 695.000
  //         }
  //     ]
  // } ;


    
     getPays( item){
      try {
        //let resp = await firstValueFrom( this.http.get<any>(`${this.payUrl}`) );
        //let resp = this.http.post<any[]>(`${this.appService.getPaysUrl()}`, item);
        let resp = this.http.get<any[]>(`${this.appService.getBaseUrl()}/param/pays`)

        
        return resp;
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
     }
  
    // send a notification 
    async sendNotification(item:any) {


      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/sendnotification`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end sendnotification

    // consulter les taux  
    async getRate(item:any) {
            try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/consultation/api/getrates`,item) );

       // let resp = this.testApiResponse ;

         console.log(resp);

        await firstValueFrom( this.http.post<any>(`${this._baseUrl}/consultation/api/getrates`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end getrate

    // Consulter l’historique des changements de taux
    async getChangeRates(item:any) {


      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/getchangerates/logs`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end getchangerates

     // nous contacter
     async contactUS(item:any) {


      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/message`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end contactUS

     //Simuler une opération de change
     async simulateOpChange(item:any) {


      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/change/simulate`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end simulateOpChange

     //Réserver une opération
     async bookTransaction(item:any) {


      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/reserve`,item) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end bookTransaction
   
    
    
    
    //async signIn for login 
     register(item: any) {
      try {
        //let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/api/authenticate/register`,item) );
        let resp = this.http.post<any[]>(`${this.appService.getBaseUrl()}/api/authenticate/register`, item)
        return resp;
        
      } catch (error: any) {
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end register

    //async signIn for login 
     logIn(item: any) {
      try {
        let resp = this.http.post<any[]>(`${this.appService.getBaseUrl()}/api/authenticate/login`, item)
        //let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/api/authenticate/login`,item) );
        return resp;
        
      } catch (error: any) {
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          }
          case error.status=== 401: { 
            throw new Error('Impossible de vérifier le compte de cet utilisateur ');
             break; 
          }   
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end logIn


     //Connection de l'inscription à l’outil de marketing 
     async newsletterRegister(item: any) {
      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/marketing/adduser`,item) );
        return resp;
        
      } catch (error: any) {
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end newsletterRegister

     //Connection de l'inscription à l’outil de marketing 
     async bookingValidity(item: any) {
      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/config/reservation`,item) );
        return resp;
        
      } catch (error: any) {
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end bookingValidity
   
    
    // consulter blog  
    async blog() {


      try {
        let resp = await firstValueFrom( this.http.get<any>(`${this._baseUrl}/blogs/latest`) );
        return resp;
        
      } catch (error: any) {
       // console.log(error);
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end getrate

    async confirmEmail(item: any) {
      try {
        let resp = await firstValueFrom( this.http.post<any>(`${this._baseUrl}/consultation/api/test`,item) );
        return resp;
        ///consultation/api/test  /api/authenticate/validate
      } catch (error: any) {
        switch(error) { 
          case error.status=== 404: { 
            throw new Error(' Le serveur ne trouve pas la ressource demandée.');
             break; 
          } 
          case error.status=== 400: { 
            throw new Error('La demande na pas pu être comprise par le serveur en raison dune syntaxe incorrecte ');
             break; 
          } 
          case error.status=== 500: { 
            throw new Error('Le serveur a rencontré une condition inattendue qui la empêché de répondre à la demande.');
             break; 
          } 
          default: { 
            throw new Error('La ressource recherchée est introuvable'); 
             break; 
          } 
       }
      }
    }// end bookingValidity

    //get group roles for user
    async saveVerification(token: string){
      let resp = await firstValueFrom( this.http.get(`${this._baseUrl}/api/authenticate/verify/${token}`) );
      return resp;
    }//fin getGroupRoles
    
    
    
    
   
   
  
  
 
  
   
  
    //  //register to news letters
    //  newsletterRegister(item: any) : Observable<any> {
    //   return this.http.post(`${this.appService.getBaseUrl()}/marketing/adduser`,
    //   item, this.appService.httpOptions)
    //     .pipe(
    //       //tap(_ => console.log(``)),
    //       catchError(this.appService.handleError<any>(``)
    //       )
    //     )
    // }//end 
  
  
  }
  