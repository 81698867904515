import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as EmailValidator from 'email-validator';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { TrinityRepository } from '../repositories/trinity.repository';
import { UtilsService } from '../services/utils/utils.service';

providers: [MessageService,TranslateService]

export class Users {
    public username: string;
    public nom: string;
    public prenom: string;
    public  telephoneGsm: string;
    public  telephoneWhatsapp: string;
    public sexe:string;
	public email: string;
    public password:string;
    public confirm_password: string;
    public errorMessage:string;
    public saveLang;
	
    constructor(
         //private translate: TranslateService
         ) {

        this.username = "";
        this.nom = "";
        this.prenom = "";
		this.sexe = "";
        this.telephoneGsm = "";
        this.telephoneWhatsapp = "";
        this.email = "";
        this.password = "";
		this.errorMessage= "";
         this.saveLang =localStorage.getItem('lang');
	}

    canRegister(): boolean {

        //description
        if (this.nom === undefined || this.nom === null  || this.nom === "") {
            // this.errorMessage = this.translate.get('ZONE_REGISTER.ERROR_MESSAGE', ).subscribe((message: string) => {
            //     this.utils.showDanger(message); return;
            //   });
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre nom "; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your lastname"; return false;
                return;
              }
        }
        if (this.nom.length >30) {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre nom  ne doit pas depasser 30 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your name must not exceed 30 characters"; return false;
                return;
              }
            
        }
        //description
        if (this.prenom === undefined || this.prenom === null  || this.prenom === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre prénom"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your firstname"; return false;
                return;
              }
            
        }

        //type_organisation
        if (this.username === undefined || this.username === null  || this.username === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre nom d'utilisateur "; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your username"; return false;
                return;
              }
            
        }
        //type_organisation
        if (this.username.length <5) {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre nom d'utilisateur doit contenir au moins 5 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your username must contain at least 5 characters"; return false;
                return;
              }
            
        }
        if (this.username.length >30) {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre nom d'utilisateur ne doit pas depasser 30 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your username must not exceed 30 characters"; return false;
                return;
              }
           
        }

        //description
        if (this.   telephoneGsm === undefined || this.   telephoneGsm === null  || this.   telephoneGsm === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre numéro de téléphone"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your phone number"; return false;
                return;
              }
            
        }
        if (this. telephoneGsm.length >20) {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre numéro de téléphone ne doit pas dépasser 20 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your phone number must not exceed 20 characters"; return false;
                return;
              }
            
        }

        //description
        if (this.  telephoneWhatsapp === undefined || this.  telephoneWhatsapp === null  || this.  telephoneWhatsapp === "") {
            
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre numéro de téléphone whatsapp"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your whatsapp phone number"; return false;
                return;
              }
        }
        if (this.telephoneWhatsapp.length >20) {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre numéro de téléphone ne doit pas dépasser 20 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your phone number must not exceed 20 characters"; return false;
                return;
              }
        }
        //photo
        if (this.email === undefined || this.email === null  || this.email === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre adresse email"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your email address"; return false;
                return;
              }
         
        }
        const isValidEmail = EmailValidator.validate(this.email);
        if (!isValidEmail) {
          
          if (this.saveLang === 'fr' || this.saveLang === undefined){
            this.errorMessage = "Veuillez fournir une adresse email valide"; return false;
            return;
          }
          if (this.saveLang === 'en'){
            this.errorMessage = "Enter a valid email address"; return false;
            return;
          }
        }
        //description
        if (this.password === undefined || this.password === null  || this.password === "") {
           
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir un mot de passe"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter a password"; return false;
                return;
              }
            
        }
        if (this.password.length <5) {
           
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre mot de passe doit contenir au moins 5 caractères"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Your password must contain at least 5 characters"; return false;
                return;
              }
        }
        if (this.password.length >10) {
            
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Votre mot de passe ne doit depasser 10 caractères"; return false;
                return;
            }
            if (this.saveLang === 'en'){
                this.errorMessage = "Your password must not exceed 10 characters"; return false;
                return;
            }
        }
        if (this.password != this.confirm_password){
          if (this.saveLang === 'fr' || this.saveLang === undefined){
            this.errorMessage = "Les mots de passe ne sont pas identiques"; return false;
            return;
          }
          if (this.saveLang === 'en'){
              this.errorMessage = "Passwords are not identical"; return false;
              return;
          }
        }
  
        return true;
  
    }//end canSave

    canLog(): boolean {

        //type_organisation
        if (this.email === undefined || this.email === null  || this.email === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir votre adresse email"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter your email address"; return false;
                return;
              }
        }
        //description
        if (this.password === undefined || this.password === null  || this.password === "") {
            if (this.saveLang === 'fr' || this.saveLang === undefined){
                this.errorMessage = "Veuillez saisir un mot de passe"; return false;
                return;
              }
              if (this.saveLang === 'en'){
                this.errorMessage = "Enter a password"; return false;
                return;
              }
        }
  
        return true;
  
    }//end canSave
    //get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }//end getErrorMessage
}