
import { Injectable } from '@angular/core';
import { TrinityRepository } from '../../repositories/trinity.repository';
  
  @Injectable()
  export class TrinityService {
    constructor(private _trinityRepo: TrinityRepository) {}
  
    //get
    getNotif(item) {
      try {
        // console.log(this._trinityRepo.sendNotif())
        return this._trinityRepo.sendNotif(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

    //get
    getrates(item:any) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getRates(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

    //get
    getChangingRate(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getChangedRates(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

     //get
     getContact(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getContact(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

     //get
     getOpSimulation(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getSimulation(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

     //get
     getTransactBooking(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getBookedTransaction(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 
  
    //g
    login(item:any) {
      try {
        return this._trinityRepo.login(item);
      } catch (error: any) {
        throw new Error(error.message);
      }
     }//end 

     //g
    register(item:any) {
      try {
        return this._trinityRepo.register(item);
      } catch (error: any) {
        throw new Error(error.message);
      }
     }//end 

     //get
    getNewsletterRegistration(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.registerNewsletter(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

     //get
    bookingValidity(item) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getbookingValidity(item);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

     //get
     getBlogContent() {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.getBlog();
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 

    //get
    accountVerification(token:any) {
      try {
        // console.log(this._trinityRepo.getRates())
        return this._trinityRepo.verifyAccount(token);
        
      } catch (error: any) {
        throw new Error(error.message);
      }
     
    }//end 
   
  
  
  
  
  }