<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Consultation</h2>
            <p>{{'consult' | translate}}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->




<!-- Start Services Area -->
<div class="services-area ptb-70 pt-0 mt-2">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">{{'titre' | translate}}</span>
                    <h2>{{'soustitre' | translate}}</h2>
                    <div class="bar"></div>
                    <p>{{'testsoustitre' | translate}}</p>

                    <a routerLink="/inscription" class="btn btn-primary">{{'img_btn_sign'| translate}}</a>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/5.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/7.jpg" alt="image" >
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">{{'fr_img_zne_t'| translate}}</span>
                    <h2>{{'fr_img_zne_title'| translate}}</h2>
                    <div class="bar"></div>
                    <p>{{'fr_img_zne_p'| translate}}.</p>

                    <a routerLink="/home" class="btn btn-primary">{{'taux_consult' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- End Services Area -->






<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button>
