<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1>Fast and easy business banking</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/" class="btn btn-primary">Create your account</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i> Play Video</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Information Area -->
<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3>
                    <p>Free account available</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>10 minutes</h3>
                    <p>Apply for account</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>$100,000</h3>
                    <p>Guarantee on deposits</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Information Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Invoicing Area -->
<div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Invoicing Area -->

<app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button>