
import { Injectable,TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Taux } from '../../models/taux';
import { TrinityRepository } from '../../repositories/trinity.repository';
import { LocalStorageService } from './localstorage.service';
import * as EmailValidator from 'email-validator';
import { environment } from 'src/environments/environment';
  
  @Injectable()
  export class UtilsService {
    private CONFIG_COUPURES_RATES = environment.configCoupuresRate;
    

    toasts: any[] = [];

    constructor(private _storageService: LocalStorageService,private toastr: ToastrService) {}

    //functipn to sort coupures
     fnSortCoupures = function(x,y) {
      if (x["nomCoupure"] > y["nomCoupure"]) {
          return 1;
      }
  
      if (x["nomCoupure"] < y["nomCoupure"]) {
          return -1;
      }
      return 0;
  }//end fnSortCoupures

    //function to sort coupures achat
    fnSortRatesAchat = function(x,y) {
    if (x["prixAchat"] > y["prixAchat"]) {
        return 1;
    }

    if (x["prixAchat"] < y["prixAchat"]) {
        return -1;
    }
    return 0;
    }//end fnSortRatesAchat

    //function to sort coupures ventes
    fnSortRatesVentes = function(x,y) {
        if (x["prixVente"] > y["prixVente"]) {
            return 1;
        }
    
        if (x["prixVente"] < y["prixVente"]) {
            return -1;
        }
        return 0;
    }//end fnSortRatesVentes
  
    //select rate line
    selectRateLine(currentDevise: string) {
      let lineTaux: Taux = new Taux();
      let rateList: any[] = this._storageService.getTauxMoyens();

      let selectedRateLineList: any = rateList.filter(x => x.nomDevise === currentDevise);
      if(selectedRateLineList.length !== 0) {
          lineTaux= selectedRateLineList[0];
      } 
      this._storageService.saveRateLine(lineTaux);
        return lineTaux;
        
    }//end selectRateLine

 
    //recuperation des taux moyens 
    //deprecated
     getAverageRates() : Taux[] {


      //console.log("je mapprete a recuperer les taux");
        let rateConfigList: any[] =  this._storageService.getCurrentRates(); 
        //console.log(rateConfigList);
        //recuperer les devises de facon unique
        var devisesFullList = rateConfigList.map(item => item["nomDevise"]);
        var devisesUniqueList = [... new Set(devisesFullList)];

        //save list devise
        let devisesStrList: string[] = [];
        for (let index = 0; index < devisesUniqueList.length; index++) {
            devisesStrList.push(devisesUniqueList[index]) ;
        }
        this._storageService.saveListDevise(devisesStrList);
        

        //pour chaque devise, afficher dans lordre croissant les taux pour la devise
        var finalRatesList: any[] = [];
        devisesUniqueList.forEach(deviseItem => {
          var listTauxByDevises = rateConfigList.filter(x => x["nomDevise"] === deviseItem ).sort(this.fnSortCoupures);

          //filtre des config par devise
            var configDeviseSearch = this.CONFIG_COUPURES_RATES.filter(x => x["nomDevise"] === deviseItem );
            if (configDeviseSearch === undefined || configDeviseSearch === null || configDeviseSearch.length === 0){
              //continue;
              console.log("Configuration non trouvée")
            //throw new Error('Configuration non trouvée')
           }else {
            let configDeviseItem = configDeviseSearch[0] ;
            //console.log(configDeviseItem);
            // console.log(listTauxByDevises);

            //let configLine  = listTauxByDevises.filter(x => x["nomCoupure"] == configDeviseItem.coupureAchat || x["nomCoupure"] == configDeviseItem.coupureVente);

            
            let configLineAchat  = listTauxByDevises.filter(x => x["nomCoupure"] == configDeviseItem.coupureAchat );
            let tauxMoyenAchat  = configLineAchat[0]["prixAchat"];
            // console.log(configLineAchat);
            // console.log(tauxMoyenAchat);

            //
            let configLineVente  = listTauxByDevises.filter(x => x["nomCoupure"] == configDeviseItem.coupureVente );
            let tauxMoyenVente  = configLineVente[0]["prixVente"];

            //get flag images 
            let flag = configDeviseItem["flagImg"];;
            // console.log(flag);

            //get currency symbols
            let currencySymbol = configDeviseItem["sign"];;
            // console.log(currencySymbol);
      
          let rateObject: any = {
            flag: flag,
            symbol:currencySymbol,
            nomDevise : deviseItem, 
            tauxMoyenAchat: tauxMoyenAchat,
            tauxMoyenVente: tauxMoyenVente,
          };

          finalRatesList.push(rateObject);
           }
           
        });

        //reconstituter la liste avec uniquement les lignes qui nous interessent
         this._storageService.saveTauxMoyens(finalRatesList);
        
         
        return finalRatesList; 
    }//end getAverageRates

    //calculer le taux a l'achat de la devise selectionné
    processCounterValue(devise:string, montant:number, isBuying: boolean){
      //select rate line
      let foundRateLine = this.selectRateLine(devise);

      //
      let rateLine: any = this._storageService.getRateline();
      
      let rate: number = (isBuying===true)? rateLine.tauxMoyenAchat : rateLine.tauxMoyenVente;

      let counterValue :number

      if ( isBuying===true ) {
         counterValue = montant * rate;
      } else {
        counterValue = montant/rate;
         counterValue = Math.round(counterValue * 100) / 100;
      }
     
      //let counterValue :number = montant * rate;

        return counterValue;
    }//end processCounterValue


    ///////////////////////////////////////////////////toast
    //show
    show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
        this.toasts.pop();
        this.toasts.push({ textOrTpl, ...options });
        setTimeout(     () => { this.toasts.pop(); }, 5000,);
      }//end show
    
      remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
      }


      //show message
      showMessage(message: string){
          alert(message);
      }//end showMessage



      showStandard(message: string) {
        this.show(message);
      }
    
      showSuccess(message: string) {
        this.toastr.success(message);
        //this.show(message, { classname: 'bg-success text-light', delay: 10000 });
      }
    
      showDanger(message: string) {
        this.toastr.error(message);
      }

      isValidEmail(email: string){
        if ( EmailValidator.validate(email) ){
   
           return true;
            }else{
   
             //else
             return false;
           }
   
       }// end checkValidEmail
  
  
  }