<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                        <p>{{ 'bottom_txt' | translate }}</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://web.facebook.com/TrinityFinances" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCVqrtGa73gJ4Ue3e9fIwvRg/" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>{{ 'lien' | translate }}</h3>

                    <ul class="list">
                        <li><a routerLink="/">{{ 'MENU.1' | translate }}</a></li>
                        <li><a routerLink="/consultation">{{ 'MENU.2' | translate }}</a></li>
                        <li><a routerLink="/contact">{{ 'MENU.4' | translate }}</a></li>
                        <li><a routerLink="/faq">{{ 'faq' | translate }}</a></li>
                        <li><a routerLink="/conditions">{{ 'cgu' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3></h3>

                    
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <ul class="list">
                        <li><a routerLink="/">Phone: +229 61185747</a></li>
                        <li><a routerLink="/">Email: info@trinitycfx.com</a> </li>
                        <li><a routerLink="/">Localisation: Cotonou, Carrefour Missèbo, en face de l'imprimerie L.D OUANOU</a> </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                        © 2023 Trinity Finance, {{ 'droit' | translate }}
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <span class="designby"> {{ 'realise' | translate }}  <a href="https://iwajutech.com/" target="_blank">IWAJJU TECH</a> </span>
                  </div>
                </div>
            </div>

        </div>
    </div>

    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
