
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { AboutComponent } from './pages/about/about.component';
import { FeaturesComponent } from './pages/features/features.component';
import { TeamComponent } from './pages/team/team.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoFourComponent } from './demos/demo-four/demo-four.component';
import { DemoFiveComponent } from './demos/demo-five/demo-five.component';
import { DemoSixComponent } from './demos/demo-six/demo-six.component';
import { BlogRightSidebarComponent } from './pages/blog-right-sidebar/blog-right-sidebar.component';
import { CanActivate } from '@angular/router';
import { 
  AuthGuardService as AuthGuard 
} from '../app/shared/services/auth/auth-guard.service';
import { 
  RoleGuardService as RoleGuard 
} from '../app/shared/services/auth/role-guard.service';
import { AccountValidationComponent } from './pages/account-validation/account-validation.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthVerificationComponent } from './pages/auth-verification/auth-verification.component';
import { CguComponent } from './pages/cgu/cgu.component';
import { PublicComponent } from './common/public/public.component';



const routes: Routes = [


    {path: '', redirectTo: '/home', pathMatch: 'full'  },

    {path: '', component: PublicComponent, 
      children: [

        {path: 'test', component: BlogComponent},
        {path: 'home', component: DefaultComponent},

        {path: 'consultation', component: FeaturesComponent,  },
        {path: 'contact', component: ContactComponent},
        {path: 'conditions', component: CguComponent},
        {path: 'faq', component: FaqComponent},
        

      ]
    },
    
    {path: 'inscription', component: SignupComponent},
    {path: 'connexion', component: SigninComponent},
    { path: 'verification/:slug', component: AuthVerificationComponent },
    {path: '**', component: NotFoundComponent}, // This line will remain down from the whole pages component list

    
      {path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard]},
      // { path: 'verification/:slug', component: AuthVerificationComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }