<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Confidentialité</h2>
            <p>{{'cgu' | translate}}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<div class="faq-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="faq-content">
                    <h2>Conditions de confidentialité</h2>
                </div>
            </div>
        </div>
        <div class="row">

            <p>Dernière mise à jour le 01 Novembre 2022 . Cette politique de confidentialité entre en vigueur
                immédiatement.
            </p>

            <h3>Introduction</h3>
       
            <p>
                Chez Trinity Finance , nous respectons votre vie privée et nous nous engageons à la protéger en nous
                conformant à cette politique.
            </p>
            <p>
                Cette politique décrit les types d'informations que nous pouvons recueillir auprès de vous ou que vous
                pouvez fournir lorsque vous visitez ce site Web (notre « site Web ») et nos pratiques de collecte,
                d'utilisation, de conservation, de protection et de divulgation de ces informations.
            </p>
            <p>Cette politique s'applique aux informations que nous collectons :</p>
            <p>Sur ce site Web.</p>
            <p>Dans les e-mails, SMS et autres messages électroniques entre vous et ce site Web.</p>
            <p>
                Grâce aux applications mobiles et de bureau que vous téléchargez à partir de ce site Web, qui
                fournissent une interaction dédiée non basée sur un navigateur entre vous et ce site Web.
            </p>
            <p>
                Lorsque vous interagissez avec nos publicités et applications sur des sites Web et services tiers, si ces
                applications ou publicités incluent des liens vers cette politique.
            </p>
            <p>
                Elle ne s'applique pas aux informations collectées par :</p>
            <p>
                nous hors ligne ou par tout autre moyen, y compris sur tout autre site Web exploité par la Société ou
                tout tiers (y compris nos sociétés affiliées et filiales) ; ou
            </p>
            <p>
                tout tiers (y compris nos sociétés affiliées et filiales), y compris par le biais de toute application ou
                contenu (y compris la publicité) qui peut être lié ou accessible depuis ou sur le site Web.
            </p>
            <p>
                Veuillez lire attentivement cette politique pour comprendre nos politiques et pratiques concernant vos
                informations et comment nous les traiterons. Si vous n'êtes pas d'accord avec nos politiques et
                pratiques, votre choix est de ne pas utiliser notre site Web. En accédant ou en utilisant ce site Web,
                vous acceptez cette politique de confidentialité. Cette politique peut changer de temps à autre. Votre
                utilisation continue de ce site Web après que nous ayons apporté des modifications est considérée comme
                une acceptation de ces modifications, veuillez donc vérifier périodiquement la politique pour les mises
                à jour.
            </p>

            <h3>Enfants de moins de 18 ans</h3>
            <p>
                Notre site Web n'est pas destiné aux enfants de moins de 18 ans. Aucune personne de moins de 18 ans ne
                peut fournir des informations personnelles sur ou sur le site Web. Nous ne collectons pas sciemment des
                informations personnelles d'enfants de moins de 18 ans. Si vous avez moins de 18 ans, n'utilisez pas ou
                ne fournissez aucune information sur ce site Web ou sur ou via l'une de ses fonctionnalités /
                enregistrez-vous sur le site Web, effectuez des achats via le site Web, utilisez l'une des
                fonctionnalités de commentaires interactifs ou publics de ce site Web ou nous fournir des informations
                vous concernant, y compris votre nom, votre adresse, votre numéro de téléphone, votre adresse e-mail ou
                tout pseudonyme ou nom d'utilisateur que vous pourriez utiliser. Si nous apprenons que nous avons
                collecté ou reçu des informations personnelles d'un enfant de moins de 18 ans sans vérification du
                consentement parental, nous supprimerons ces informations. Si vous pensez que nous pourrions avoir des
                informations de ou sur un enfant de moins de 18 ans,
            </p>

            <h3>Informations que nous recueillons à votre sujet et comment nous les recueillons</h3>
            <p>
                Nous recueillons plusieurs types d'informations de et sur les utilisateurs de notre site Web, y compris
                des informations :
            </p>
            <p>par lesquels vous pouvez être personnellement identifié, comme le nom, l'adresse postale, l'adresse e-mail, le numéro de téléphone ou TOUTE AUTRE INFORMATION COLLECTÉE PAR LE SITE WEB QUI EST DÉFINIE COMME INFORMATION PERSONNELLE OU PERSONNELLEMENT IDENTIFIABLE EN VERTU D'UNE LOI APPLICABLE (« informations personnelles ») ;</p>
            <p>qui vous concerne mais ne vous identifie pas individuellement, et/ou</p>
            <p>sur votre connexion Internet, l'équipement que vous utilisez pour accéder à notre site Web et les détails d'utilisation.</p>
            <p>Nous collectons ces informations :</p>
            <p>Directement de vous lorsque vous nous le fournissez.</p>
            <p>
                Automatiquement lorsque vous naviguez sur le site. Les informations collectées automatiquement peuvent inclure des détails d'utilisation, des adresses IP et des informations collectées via des cookies, des balises Web et d'autres technologies de suivi.
            </p>
            <p>
                De tiers, par exemple, nos partenaires commerciaux.
            </p>

            <h3>Informations que vous nous fournissez.</h3>
            <p>Les informations que nous recueillons sur ou via notre site Web peuvent inclure :</p>
            <p>
                Les informations que vous fournissez en remplissant des formulaires sur notre site Web. Cela inclut les
                informations fournies au moment de l'enregistrement pour utiliser notre site Web, de la souscription à
                notre service, de la publication de matériel ou de la demande de services supplémentaires. Nous pouvons
                également vous demander des informations lorsque vous signalez un problème avec notre site Web.
            </p>
            <p>
                Les enregistrements et les copies de votre correspondance (y compris les adresses e-mail), si vous nous
                contactez.
            </p>
            <p>
                Vos réponses aux sondages que nous pourrions vous demander de remplir à des fins de recherche.
            </p>
            <p>
                Détails des transactions que vous effectuez via notre site Web et de l'exécution de vos commandes. Vous
                devrez peut-être fournir des informations financières avant de passer une commande sur notre site Web.
            </p>
            <p>Vos requêtes de recherche sur le site Web.</p>
            <p>
                Vous pouvez également fournir des informations à publier ou à afficher (ci-après, « postées ») sur les
                zones publiques du site Web, ou transmises à d'autres utilisateurs du site Web ou à des tiers
                (collectivement, les « contributions de l'utilisateur »). Vos contributions d'utilisateur sont publiées
                et transmises à d'autres à vos risques et périls. Bien que nous limitions l'accès à certaines pages/vous
                puissiez définir certains paramètres de confidentialité pour ces informations en vous connectant au
                profil de votre compte, sachez qu'aucune mesure de sécurité n'est parfaite ou impénétrable. De plus,
                nous ne pouvons pas contrôler les actions des autres utilisateurs du site Web avec lesquels vous pouvez
                choisir de partager vos contributions d'utilisateur. Par conséquent, nous ne pouvons garantir et ne
                garantissons pas que vos contributions d'utilisateur ne seront pas vues par des personnes non
                autorisées.
            </p>

            <h3>Informations que nous recueillons grâce aux technologies de collecte automatique de données.</h3>
            <p>
                Lorsque vous naviguez et interagissez avec notre site Web, nous pouvons utiliser des technologies de
                collecte automatique de données pour collecter certaines informations sur votre équipement, vos actions
                et vos habitudes de navigation, notamment :
            </p>
            <p>
                Les détails de vos visites sur notre site Web, y compris les données de trafic, les données de
                localisation et d'autres données de communication et les ressources auxquelles vous accédez et que vous
                utilisez sur le site Web.
            </p>
            <p>Informations sur votre ordinateur et votre connexion Internet, y compris votre adresse IP, votre système
                d'exploitation et votre type de navigateur.
            </p>
            <p>
                Nous pouvons également utiliser ces technologies pour collecter des informations sur vos activités en
                ligne au fil du temps et sur des sites Web tiers ou d'autres services en ligne (suivi comportemental).
                Les informations que nous collectons automatiquement sont des données statistiques et n'incluent pas
                d'informations personnelles, mais nous pouvons les conserver ou les associer à des informations
                personnelles que nous collectons d'autres manières ou que nous recevons de tiers. Cela nous aide à
                améliorer notre site Web et à fournir un service meilleur et plus personnalisé, notamment en nous
                permettant de :
            </p>
            <p>Estimez la taille de notre audience et les modèles d'utilisation.</p>
            <p>
                Stocker des informations sur vos préférences, nous permettant de personnaliser notre site Web en fonction
                de vos intérêts individuels.
            </p>
            <p>Accélérez vos recherches.</p>
            <p>Vous reconnaître lorsque vous revenez sur notre site Web.</p>
            <p>Les technologies que nous utilisons pour cette collecte automatique de données peuvent inclure :</p>

            <p>
                Cookies (ou cookies de navigateur). Un cookie est un petit fichier déposé sur le disque dur de votre
                ordinateur. Vous pouvez refuser d'accepter les cookies du navigateur en activant le paramètre approprié
                sur votre navigateur. Cependant, si vous sélectionnez ce paramètre, vous ne pourrez peut-être pas
                accéder à certaines parties de notre site Web. À moins que vous n'ayez ajusté les paramètres de votre
                navigateur pour qu'il refuse les cookies, notre système émettra des cookies lorsque vous dirigerez votre
                navigateur vers notre site Web.
            </p>
            <p>
                Cookies Flash. Certaines fonctionnalités de notre site Web peuvent utiliser des objets stockés locaux
                (ou cookies Flash) pour collecter et stocker des informations sur vos préférences et votre navigation
                vers, depuis et sur notre site Web. Les cookies Flash ne sont pas gérés par les mêmes paramètres de
                navigateur que ceux utilisés pour les cookies de navigateur.
            </p>
            <p>
                Balises Web. Les pages de notre site Web peuvent contenir de petits fichiers électroniques connus sous
                le nom de balises Web (également appelés gifs transparents, balises pixel et gifs à pixel unique) qui
                permettent à la Société, par exemple, de compter les utilisateurs qui ont visité ces pages ou ouvert un
                e-mail. -mail et pour d'autres statistiques de site Web connexes (par exemple, enregistrer la popularité
                de certains contenus de site Web et vérifier l'intégrité du système et du serveur).
            </p>
            <p>
                Nous ne collectons pas d'informations personnelles automatiquement, mais nous pouvons lier ces
                informations à des informations personnelles vous concernant que nous collectons auprès d'autres sources
                ou que vous nous fournissez.
            </p>

            <h3>Utilisation par des tiers de cookies et d'autres technologies de suivi.</h3>
            <p>
                Certains contenus ou applications, y compris les publicités, sur le site Web sont diffusés par des
                tiers, notamment des annonceurs, des réseaux et serveurs publicitaires, des fournisseurs de contenu et
                des fournisseurs d'applications. Ces tiers peuvent utiliser des cookies seuls ou en conjonction avec des
                balises Web ou d'autres technologies de suivi pour collecter des informations vous concernant lorsque
                vous utilisez notre site Web. Les informations qu'ils collectent peuvent être associées à vos
                informations personnelles ou ils peuvent collecter des informations, y compris des informations
                personnelles, sur vos activités en ligne au fil du temps et sur différents sites Web et autres services
                en ligne. Ils peuvent utiliser ces informations pour vous fournir des publicités (comportementales)
                basées sur vos centres d'intérêt ou d'autres contenus ciblés.
            </p>
            <p>
                Nous ne contrôlons pas les technologies de suivi de ces tiers ni la manière dont elles peuvent être
                utilisées. Si vous avez des questions sur une publicité ou un autre contenu ciblé, vous devez contacter
                directement le fournisseur responsable.
            </p>

            <h3>Comment nous utilisons vos informations</h3>

            <p>
                Nous utilisons les informations que nous recueillons à votre sujet ou que vous nous fournissez, y compris
                toute information personnelle :
            </p>
            <p>Pour vous présenter notre site Web et son contenu.</p>
            <p>Pour vous fournir les informations, produits ou services que vous nous demandez.</p>
            <p>Pour vous fournir des informations sur nos services</p>
            <p>Pour vous fournir des avis concernant votre compte/abonnement, y compris les avis d'expiration et de
                renouvellement.</p>
            <p>Pour exécuter nos obligations et faire valoir nos droits découlant de tout contrat conclu entre vous et
                nous, y compris pour la facturation et le recouvrement.</p>
            <p>Pour vous informer des modifications apportées à notre site Web ou à tout produit ou service que nous
                offrons ou fournissons par son intermédiaire.</p>
            <p>Pour vous permettre de participer à des fonctions interactives sur notre site Web.</p>
            <p>De toute autre manière, nous pouvons décrire le moment où vous fournissez les informations.</p>
            <p>Pour remplir tout objectif pour lequel vous le fournissez.</p>
            <p>Pour toute autre fin avec votre consentement.</p>
            <p>
                Nous pouvons utiliser les informations que nous avons recueillies auprès de vous pour nous permettre
                d'afficher des publicités aux publics cibles de nos annonceurs. Même si nous ne divulguons pas vos
                informations personnelles à ces fins sans votre consentement, si vous cliquez sur ou interagissez
                autrement avec une publicité, l'annonceur peut supposer que vous répondez à ses critères cibles.
            </p>

            <h3>Divulgation de vos informations</h3>

            <p>
                Nous pouvons divulguer des informations agrégées sur nos utilisateurs et des informations qui
                n'identifient aucun individu, sans restriction.
                Nous pouvons divulguer les informations personnelles que nous collectons ou que vous fournissez comme
                décrit dans cette politique de confidentialité :
            </p>
            <p>À nos filiales et sociétés affiliées.</p>
            <p>
                Aux sous-traitants, prestataires de services et autres tiers que nous utilisons pour soutenir nos
                activités et qui sont tenus par des obligations contractuelles de garder les informations personnelles
                confidentielles et de les utiliser uniquement aux fins pour lesquelles nous les leur divulguons.
            </p>
            <p>
                A un acquéreur ou autre successeur en cas de fusion, cession, restructuration, réorganisation,
                dissolution ou autre vente ou transfert de tout ou partie des actifs de la Société, que ce soit dans le
                cadre de la continuité d'exploitation ou dans le cadre d'une faillite, liquidation ou procédure
                similaire, dans lequel les informations personnelles détenues par la Société sur les utilisateurs de
                notre site Web font partie des actifs transférés.
            </p>
            <p>
                À des tiers pour vous commercialiser leurs produits ou services si vous avez consenti à ces
                divulgations. Nous exigeons contractuellement de ces tiers qu'ils gardent les informations personnelles
                confidentielles et les utilisent uniquement aux fins pour lesquelles nous les leur communiquons.
            </p>
            <p>
                Pour remplir le but pour lequel vous le fournissez. Par exemple, si vous nous donnez une adresse e-mail
                pour utiliser la fonction "envoyer un e-mail à un ami" de notre site Web, nous transmettrons le contenu
                de cet e-mail et votre adresse e-mail aux destinataires.
            </p>
            <p>Pour toute autre fin divulguée par nous lorsque vous fournissez les informations.</p>
            <p>Avec votre accord.</p>

            <p>Nous pouvons également divulguer vos informations personnelles :</p>
            <p>Pour se conformer à toute ordonnance d'un tribunal, loi ou procédure judiciaire, y compris pour répondre
                à toute demande gouvernementale ou réglementaire.</p>
            <p>Pour faire respecter ou appliquer nos conditions d'utilisation et autres accords, y compris à des fins de
                facturation et de recouvrement.</p>
            <p>
                Si nous pensons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété
                ou la sécurité de la Société, de nos clients ou d'autres personnes. Cela inclut l'échange d'informations
                avec d'autres sociétés et organisations à des fins de protection contre la fraude et de réduction du
                risque de crédit.
            </p>

            <h3>Choix concernant la manière dont nous utilisons et divulguons vos informations</h3>

            <p>
                Nous nous efforçons de vous offrir des choix concernant les informations personnelles que vous nous
                fournissez. Nous avons créé des mécanismes pour vous fournir le contrôle suivant sur vos informations :
            </p>
            <p>
                Technologies de suivi et publicité. Vous pouvez configurer votre navigateur pour qu'il refuse tout ou
                partie des cookies du navigateur, ou qu'il vous avertisse lorsque des cookies sont envoyés. Pour savoir
                comment vous pouvez gérer vos paramètres de cookies Flash, visitez la page des paramètres du lecteur
                Flash sur le site Web d'Adobe. Si vous désactivez ou refusez les cookies, veuillez noter que certaines
                parties de ce site peuvent alors être inaccessibles ou ne pas fonctionner correctement.
            </p>
            <p>
                Nous ne contrôlons pas la collecte ou l'utilisation de vos informations par des tiers pour diffuser des
                publicités ciblées par centres d'intérêt. Cependant, ces tiers peuvent vous fournir des moyens de
                choisir de ne pas faire collecter ou utiliser vos informations de cette manière. Vous pouvez refuser de
                recevoir des publicités ciblées des membres de la Network Advertising Initiative (« NAI ») sur le site
                Web de la NAI.
            </p>

            <h3>Accès et correction de vos informations</h3>
            <p>
                Vous pouvez nous envoyer un e-mail via notre lien de contact pour demander l'accès, la correction ou la
                suppression de toute information personnelle que vous nous avez fournie. Nous ne pouvons supprimer vos
                informations personnelles qu'en supprimant également votre compte utilisateur. Nous pouvons ne pas
                accepter une demande de modification d'informations si nous pensons que la modification violerait une
                loi ou une exigence légale ou rendrait les informations incorrectes.
            </p>
            <p>
                Si vous supprimez vos contributions d'utilisateur du site Web, des copies de vos contributions
                d'utilisateur peuvent rester visibles dans les pages mises en cache et archivées, ou peuvent avoir été
                copiées ou stockées par d'autres utilisateurs du site Web. L'accès et l'utilisation appropriés des
                informations fournies sur le site Web, y compris les contributions des utilisateurs, sont régis par nos
                conditions d'utilisation.
            </p>

            <h3>Vos droits de confidentialité en Californie</h3>
            <p>
                L'article 1798.83 du Code civil de Californie permet aux utilisateurs de notre site Web qui sont des
                résidents de Californie de demander certaines informations concernant notre divulgation d'informations
                personnelles à des tiers à des fins de marketing direct. Pour faire une telle demande, veuillez nous
                contacter via le lien Contactez-nous.
            </p>

            <h3>Sécurité des données</h3>
            <p>
                Nous avons mis en place des mesures conçues pour protéger vos informations personnelles contre la perte
                accidentelle et contre l'accès, l'utilisation, la modification et la divulgation non autorisés.
            </p>
            <p>
                La sûreté et la sécurité de vos informations dépendent également de vous. Lorsque nous vous avons donné
                (ou lorsque vous avez choisi) un mot de passe pour accéder à certaines parties de notre site Web, vous
                êtes responsable de la confidentialité de ce mot de passe. Nous vous demandons de ne partager votre mot
                de passe avec personne.
            </p>
            <p>
                Malheureusement, la transmission d'informations via Internet n'est pas totalement sécurisée. Bien que
                nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons garantir la
                sécurité de vos informations personnelles transmises à notre site Web. Toute transmission d'informations
                personnelles se fait à vos risques et périls. Nous ne sommes pas responsables du contournement des
                paramètres de confidentialité ou des mesures de sécurité contenus sur le site Web.
            </p>

            <h3>Modifications de notre politique de confidentialité</h3>
            <p>
                Notre politique est de publier toute modification que nous apportons à notre politique de
                confidentialité sur cette page. Si nous apportons des modifications importantes à la manière dont nous
                traitons les informations personnelles de nos utilisateurs, nous vous en informerons par e-mail à
                l'adresse e-mail indiquée dans votre compte et/ou via un avis sur la page d'accueil du site Web. La date
                à laquelle la politique de confidentialité a été révisée pour la dernière fois est identifiée en haut de
                la page. Vous êtes responsable de vous assurer que nous avons une adresse e-mail active et livrable à
                jour pour vous, et de visiter périodiquement notre site Web et cette politique de confidentialité pour
                vérifier tout changement.
            </p>


            <h3>Coordonnées</h3>
            <p>
                Pour poser des questions ou commenter cette politique de confidentialité et nos pratiques de
                confidentialité, contactez-nous via notre lien <a href="#">Contactez-nous</a>.
                Merci de  <a href="">visiter le site Web</a>.
            </p>
        </div>
    </div>
</div>
<!-- End FAQ Area -->

<app-account-button></app-account-button>