<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Réservation</h2>
            <p>Résevez votre Change</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->


<div class="d-table">
    <div class="d-table-cell">
        <div class="container">
            <div *ngIf="showLoginLink" class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="banner-content">
                        <h1 style="color:black ;">Connectez vous pour effectuert une réservation</h1>
                        <a routerLink="/signin" class="btn btn-primary">Se connecter</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="money-transfer-form" style="visibility: hidden;">

                      <p-tabView>
                            <p-tabPanel header="J'achète">
                                <form>
                                    <div class="form-group mb-3">
                                        <label>J'achète</label>
                                        <div class="money-transfer-field">
                                            <input type="text" class="form-control" autocomplete="off" value="500">
                                            <div class="amount-currency-select">
                                                <select>
                                                    <option>USD</option>
                                                    <option>EUR</option>
                                                    <option>GBP</option>
                                                    <option>CHF</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency-info">
                                        <div class="bar"></div>

                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Je remet</label>
                                        <div class="money-transfer-field">
                                            <input type="text" class="form-control" autocomplete="off" value="331 504,30">
                                            <div class="amount-currency-select">
                                                <select>
                                                    <option>FCFA</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="money-transfer-info">
                                        <span>Taux du 03/08/2022 à 15 : 35<strong>600 </strong>à l'achat</span>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Commencer</button>
                                    <div class="terms-info">
                                        <p>En cliquant ici, vous acceptez <a routerLink="/#" routerLinkActive="active">les conditions d'utilisations</a></p>
                                    </div>
                                </form>
                            </p-tabPanel>
                            <p-tabPanel header="Je vend">
                                <form>
                                    <div class="form-group mb-3">
                                        <label>Je vend</label>
                                        <div class="money-transfer-field">
                                            <input type="text" class="form-control" autocomplete="off" value="500">
                                            <div class="amount-currency-select">
                                                <select>
                                                    <option>USD</option>
                                                    <option>EUR</option>
                                                    <option>GBP</option>
                                                    <option>CHF</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency-info">
                                        <div class="bar"></div>




                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Je remet</label>
                                        <div class="money-transfer-field">
                                            <input type="text" class="form-control" autocomplete="off" value="148 257,58">
                                            <div class="amount-currency-select">
                                                <select>
                                                    <option>FCFA</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="money-transfer-info">
                                        <span>Taux du 03/08/2022 à 15 : 35 <strong>650 </strong>à la vente</span>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Commencer</button>
                                    <div class="terms-info">
                                        <p>En cliquant ici, vous acceptez <a routerLink="/#" routerLinkActive="active">les conditions d'utilisations</a></p>
                                    </div>
                                </form>
                            </p-tabPanel>
                        </p-tabView> 


                    </div>
                </div>
            </div>
            <!-- Start Comparisons Area -->
            <div *ngIf="!showLoginLink" class="comparisons-area ptb-70 bg-f6f4f8">
                <div class="container">
                    <div class="section-title">
                        <h2>Historiques des transactions </h2>
                        <div class="bar"></div>
                        <p>Découvrez les taux actuels par devise à l'achat et à la vente.</p>
                    </div>
                    <div class="comparisons-table table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Devise</th>
                                    <th scope="col">Achat</th>
                                    <th scope="col">Vente</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td class="fw-">qfbeq</td>
                                    <td><i></i>dgbsgsdb</td>
                                    <td><i></i>gdsbeqdg</td>
                                    <td><i></i>dbvds</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- End Comparisons Area -->
        </div>
    </div>
</div>



<!-- Start Pricing Area -->

<!-- End Pricing Area -->

<app-account-button></app-account-button>
