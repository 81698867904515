<!-- Start Ready To Talk Area -->
<div class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>{{ 'talk_btn' | translate }}</h3>
            <p>{{ 'talk_btn_txt' | translate }}</p>
            <a routerLink="/public/contact" class="btn btn-primary">{{ 'talk_btn_2' | translate }}</a>
            <!-- <span><a routerLink="/">{{ 'img_btn_create' | translate }}</a></span> -->
        </div>
    </div>
</div>
<!-- End Ready To Talk Area -->
