import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Users } from 'src/app/shared/models/users';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import { LocalStorageService } from 'src/app/shared/services/utils/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  user : Users= new Users;
  userConnected:any;
  chargement : boolean = false;
  results:any;

  constructor( private utils:UtilsService,private router: Router,private trinityRepo: TrinityRepository,private localStorageService: LocalStorageService, private translate: TranslateService) { }

  ngOnInit() {
  }

  login(){
    if(this.user.canLog() === false){
      this.utils.showDanger(this.user.getErrorMessage());
      return;
    }
    this.chargement = true;
    console.log(this.user);
    this.trinityRepo.login(this.user).subscribe((result) => { 
    this.chargement = false;
    this.userConnected = this.localStorageService.saveUserConnected(this.user);
    this.translate.get('ZONE_LOGIN.SUCCESS_MESSAGE', ).subscribe((message: string) => {
      this.utils.showSuccess(message); return;
    });
    setTimeout(() => {
      this.router.navigate(["/"]);
   }, 1000);
     
    if (result === undefined) {
        let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
        console.log(error);
    }
    }, (error: any) => {
      let errorMsg: string = error.error.message;
      //console.log(error)
      this.translate.get('ZONE_LOGIN.ERROR_MESSAGE', ).subscribe((message: string) => {
        this.utils.showDanger(message); return;
      });
      //this.utils.showDanger(errorMsg);
      this.chargement = false;
      return;

    })
  }
  backTo(){
    
    this.router.navigate(['/']);
  }

}
