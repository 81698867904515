import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/shared/models/users';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [MessageService]
})
export class SignupComponent implements OnInit {

  user : Users= new Users;
  chargement : boolean = false;
  countryList:any= [];
  selectedCountry: any;
  message:any;
  

  constructor(private router: Router,private trinityRepo: TrinityRepository,private messageService: MessageService, private utils:UtilsService,private toastr: ToastrService, private translate: TranslateService) { 


  

  }

  ngOnInit() {
    //this.getCountry();
    //this.utils.showDanger(" ghesbvkjerqds ");
    this.user.sexe= "M";
    this.user.password= "password";
    this.user.confirm_password= "password";
    this.user.username= "username";
    this.user.telephoneGsm= "00000000";
    //console.log(this.user)
    //this.countryList = JSON.parse(localStorage.getItem("payList"));
    console.log(this.countryList)

    // empty message trnslate
     this.translate.get('EMPTY', ).subscribe((message: string) => {
      this.message =message; return;
    }); 
    const item = { key: 'value' };

    //get countryList 
    this.trinityRepo.getPAysList(item).subscribe((result:any) => {
      this.countryList = result.data;


      console.log(this.countryList);
      //localStorage.setItem("payList",JSON.stringify(pays))
     
    }, (error: any) => {})
  }

  getCountry(){
    const item = { key: 'value' };
  this.trinityRepo.getPAysList(item).subscribe((result:any) => {
    //console.log(result.$values)
    let pays = result.$values;
    localStorage.setItem("payList",JSON.stringify(pays))
   
  }, (error: any) => {})
 }

  register(){
      if(this.user.canRegister() === false){
        this.utils.showDanger(this.user.getErrorMessage());
        return;
      }
      // if (this.user.password != this.user.confirm_password){
      //   this.utils.showDanger('Les mots de passe ne sont pas identiques');
      //   return;
      // }
    this.chargement = true;

    this.trinityRepo.register(this.user).subscribe((result) => {
      //console.log(result)
      this.chargement = false;
    //this.userConnected = this.localStorageService.saveUserConnected(this.user);
    this.translate.get('ZONE_REGISTER.SUCCESS_MESSAGE', ).subscribe((message: string) => {
      this.utils.showSuccess(message); return;
    });

    setTimeout(() => {
       this.router.navigate(["/"]);
    }, 5000);
     
    if (result === undefined) {
        let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
        console.log(error);
    }
    }, (error: any) => {
      let errorMsg: string = error.error.message;
      this.translate.get('ZONE_REGISTER.ERROR_MESSAGE', ).subscribe((message: string) => {
        this.utils.showDanger(message); return;
      });
      this.chargement = false;
      return;

    })
  }
  backTo(){
    
    this.router.navigate(['/']);
  }

}
