import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { ModalComponent } from 'src/app/modal/modal.component';
import { SigninComponent } from 'src/app/pages/signin/signin.component';
import { Taux } from 'src/app/shared/models/taux';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import { LocalStorageService } from 'src/app/shared/services/utils/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { BehaviorSubject } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { FormatmoneyPipe } from 'src/app/shared/pipes/formatmoney.pipe';
import { ApiConfig } from 'src/app/shared/api/app.config';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],

})
export class DefaultComponent implements OnInit {
  @HostListener('window:storage')

  taux: Taux = new Taux;
  variableTaux: any[] = [];
  boardRates: any[] = [];
  amBuying: number = 0;
  amSelling: number = 0;
  isBuying: boolean = true;
  counterValueAchat: number = 0;
  counterValueBuy: number = 0;
  counterValueSell: number = 0;
  counterValueVente: number = 0;
  selectedDevise: string = ""; 
  selectedDeviseAchat: string = ""; selectedDeviseVente: string = "";
  selectedDeviseRate: Taux = new Taux();
  selectedLineTauxAchat :any;
  selectedLineTauxVente :any;
  deviseList: string[] = [];
  safeURL: any = {};
  videoURL: string = "https://www.youtube.com/embed/_GLjygwVeCc";
  symbol:any;
  isLoading:boolean = false;
  saveLang: any = {};
  identity = new BehaviorSubject('');
  id:any;
  derniereExecution = null;

  constructor(private _sanitizer: DomSanitizer,private trinityRepo: TrinityRepository, private utils: UtilsService, private appConfig: ApiConfig, private _storageService: LocalStorageService,  private modalService: NgbModal,
    private ref: ChangeDetectorRef, private translate: TranslateService) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
    // this.identity.next('lang');
    // console.log(this.identity)
    
   }

   ngOnInit() {
    
      this.initData();
     this.initDemoData();
     this.saveLang = localStorage.getItem('lang');


     
     

      setInterval(() => {
      this.initData();
      
    }, this.appConfig.getRateChangeInterval());
  
     
     
      //formatAmount = new FormatMoneyPipe().transform(formule.montant, '');
    // this.openModal();
    // this.identity.subscribe(res => {
    //   this.id = res;
    // });
    // console.log(this.id)
    
   }

   scrollPageDown() {
    window.scrollTo({ top: 10000, behavior: 'smooth' }); // Adjust the value of 'top' as needed
  }
   onStorageChange() {
    console.log('change...');
    console.log(localStorage.getItem('lang'));
  }
  // initDataDemo
  async initDemoData() {

    //console.log('Nous sommes ici');
    //console.log('Nous sommes la');

    this.isLoading = true;
    //console.log('La méthode initData est appelée toutes les 30 secondes');
    //get taux configured
     await this.getChangingRate(this.taux);
 
    //process taux for display on board
     this.boardRates = this.utils.getAverageRates();
     //this.getBoardRates();
     //console.log(this.boardRates);

     //list devises
     this.deviseList = this._storageService.getListDevise();
    
     

     this.ref.detectChanges();
     //console.log(this.selectedDevise);

     //select devise
     this.selectedDeviseRate = this.selectRateByDevise(this.selectedDevise);
     //console.log(this.selectedDeviseRate);
     this.isLoading = false;
     
  }
  
  //finDataDemo
  // 
  //init data
   async initData() {
    let lastExecutionTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      // Fonction pour arrondir l'heure à la prochaine demi-heure
    

      // Fonction pour afficher l'heure de la dernière exécution
      function afficherHeureDerniereExecution() {
        const heureContainer = document.getElementById('heure-container');
        if (heureContainer) {
          heureContainer.textContent = 'Dernier Taux : ' + lastExecutionTime;
        }
      }
      afficherHeureDerniereExecution();
      setInterval(afficherHeureDerniereExecution, 120000);

    this.isLoading = true;
    console.log('La méthode initData est appelée toutes les 30 secondes');
    //get taux configured
     await this.getChangingRate(this.taux);
 
    //process taux for display on board
     this.boardRates = this.utils.getAverageRates();
     //this.getBoardRates();
     //console.log(this.boardRates);

     //list devises
     this.deviseList = this._storageService.getListDevise();
    
     

     this.ref.detectChanges();
     //console.log(this.selectedDevise);

     //select devise
     this.selectedDeviseRate = this.selectRateByDevise(this.selectedDevise);
     //console.log(this.selectedDeviseRate);
     this.isLoading = false;
     
  }//end ngoninit

  //index change
  handleTabIndexChange(e){
    var index = e.index;
    if(index === 0 ){
      this.isBuying = true; 
    }else{
      this.isBuying = false; 
    }
    // console.log(e);
    // console.log(this.isBuying);
  }

  //update devise selection
  updateDeviseSelection(event: any){
    this.selectedDeviseAchat = event;
    if (this.selectedDeviseAchat != null ){
      //console.log('vhgvh');
      this.counterValueBuy = 0;
      this.amBuying =0;
    }
    this.isBuying = true; 
    // console.log(event); 
    // console.log(this.selectedDeviseAchat);
    let taux:any = this.selectRateByDevise(this.selectedDeviseAchat);
    //console.log(taux)
    this.symbol= taux.symbol;
    this.selectedLineTauxAchat = taux.tauxMoyenAchat;
    //console.log(this.selectedDevise);
  }//end updateDeviseSelection

  //update devise selection
  updateDeviseSelectionVente(event: any){
    this.selectedDeviseVente = event;
    if (this.selectedDeviseAchat != null ){
      //console.log('vhgvh');
      this.counterValueSell = 0;
      this.amSelling =0;
    }
    this.isBuying = false; 
    //this.selectedDeviseRate = this.selectRateByDevise(this.selectedDeviseVente);
    let taux:any = this.selectRateByDevise(this.selectedDeviseVente);
    this.symbol= taux.symbol;
    this.selectedLineTauxVente = taux.tauxMoyenVente;
    //console.log(this.selectedLineTauxVente);
  }//end updateDeviseSelection

  //on buying change event
  onBuyingChangeEvent(event: any){
    this.amBuying = event.target.value;
    this.counterValueAchat = this.processAmountEstimation();
  }//end onBuyingChangeEvent

  //calculate buy
  calculateBuyAmount(){
    if (this.selectedDeviseAchat ===undefined || this.selectedDeviseAchat ===null || this.selectedDeviseAchat ===''  ){
      this.translate.get('ZONE_ERRORS.SELECT_CURRENCY', ).subscribe((message: string) => {
          this.utils.showDanger(message); return;
      });return;
    }

    if (this.amBuying === 0  || this.amBuying === null  ||  this.amBuying === undefined  ){
        this.translate.get('ZONE_ERRORS.ENTER_AMOUNT', ).subscribe((message: string) => {
          this.utils.showDanger(message); return;
      });return;
    }
    this.counterValueBuy = this.processAmountEstimation();
  }//end calculateBuyAmount

  //calculate sell
  calculateSellAmount(){
    if (this.selectedDeviseVente ===undefined || this.selectedDeviseVente ===null || this.selectedDeviseVente ===''  ){
      this.translate.get('ZONE_ERRORS.SELECT_CURRENCY', ).subscribe((message: string) => {
          this.utils.showDanger(message); return;
      });return;
    }

    if (this.amSelling === 0  || this.amSelling === null  ||  this.amSelling === undefined  ){
        this.translate.get('ZONE_ERRORS.ENTER_AMOUNT', ).subscribe((message: string) => {
          this.utils.showDanger(message); return;
      });return;
    }
    this.counterValueSell = this.processAmountEstimation();
  }//end calculateSellAmount

  //on selling change event
  onSellingChangeEvent(event: any){
    //console.log(event.target.value);
    this.amSelling = event.target.value;
    //this.amSelling = event.target.value;
    this.counterValueVente = this.processAmountEstimation();
  }//end onSellingChangeEvent
  
  //get rate
  async getChangingRate(item:any){
    this.variableTaux = await this.trinityRepo.getRates(item);
  }//end getChangingRate


  //process amount estimation
  processAmountEstimation(){
    let montant = (this.isBuying)? this.amBuying : this.amSelling; 
    let selectedDevise = (this.isBuying)? this.selectedDeviseAchat : this.selectedDeviseVente; 

    // console.log(selectedDevise);
    // console.log(montant);
    // console.log(this.isBuying);

    let processedEstimation: number = this.utils.processCounterValue(selectedDevise, montant, this.isBuying);

    return processedEstimation;
  }//end processAmountEstimation



  //on update
  onBuyingUpdate(){
    this.processAmountEstimation();
  }//end onBuyingUpdate

  //on update
  onSellingUpdate(){
    this.processAmountEstimation();
  }//end onBuyingUpdate


   //select rate by devise
   selectRateByDevise(deviseCode: any){
      let processedRate: Taux = new Taux();
      processedRate = this.utils.selectRateLine(deviseCode);
      //console.log(processedRate);
      return processedRate;
  }//end selectRateByDevise

  bookTransaction(){
    this.utils.showSuccess('Votre opération a été réservé' );
  }

  openModal(){
    const ModalRef = this.modalService.open(ModalComponent, { size : "lg", backdrop : "static" }).result.then((result) =>{
      
    }, (reason) => {
     
    });
  }

  transform(value: any ) {
    var moneyFormatted = ""; var moneyLength = 0;
    //controle de la valeur entrée
    if (value === null || value == undefined) return value;
    //sa longueur
    moneyLength = value.length;
    //inverser la chaine de caractere
    var copiedInput = new String(value);
    var inversedInput = copiedInput.split('').reverse().join('');
    //diviser en groupes de 3
    for (var i =0; i<(inversedInput.length/3); i++){
      moneyFormatted += " " + inversedInput.substring(i*3, i*3+3);
    }
    //reinverse
    var copiedFormated = new String(moneyFormatted);
    var inversedFormated = copiedFormated.split('').reverse().join('');
    return inversedFormated;
  }






}

