import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser'
import { AppService } from './services/utils/app.service';
import {RouterModule} from '@angular/router';
//import {CardModule} from 'primeng/card';
//import { FedaPayCheckoutModule } from 'fedapay-angular';
import { FormsModule } from '@angular/forms';
import { TrinityRepository } from './repositories/trinity.repository';
import { ApiCenter } from './api/api.center';
import { LimitToPipe } from './pipes/limitto.pipe';
import { I18nServiceService } from './services/utils/i18n-service.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ToastModule } from 'primeng/toast';
import { ToastService } from './services/utils/toast.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormatmoneyPipe } from './pipes/formatmoney.pipe';


@NgModule({
    declarations: [
     //ImageSlideComponent,
     LimitToPipe,
     FormatmoneyPipe,
    ],
    exports: [
      //modules
      CommonModule,
      LimitToPipe,
      FormatmoneyPipe,
    ],
    schemas : [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      BrowserModule,
      HttpClientModule,
      FormsModule,
      CommonModule,
      RouterModule,ToastModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        isolate: true,
      }),
    ],
    providers: [HttpClient,AppService,TrinityRepository,ApiCenter, LimitToPipe,I18nServiceService,ToastService],
    
    bootstrap: []
  })
export class SharedModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
 }