<body class="">
    <!-- Start Page Title Area -->
  <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
      <div class="container">
          <div class="page-title-content">
              <h2 class="text-white">Verification</h2>
              <p></p>
          </div>
      </div>
  </div>
  <!-- End Page Title Area -->
  <table border="0" cellpadding="0" cellspacing="0" class="body mt-5 bg-white">
    <tr>
      <td>&nbsp;</td>
      <td class="container">
        <div class="content">
          <table class="main">
            <tr>
              <div class="card-body" >
                <img src="assets/images/logo-dark.png" alt="" class="img-fluid mb-4">
                <h4 class="mb-3 f-w-400 text-center">Vérification de compte</h4>
                <p class="text-center" *ngIf="responseName!= '' ">{{responseName}} {{responseSurname}}, votre compte est activé </p>
                <a class="btn btn-block btn-primary mb-4 text-white" [routerLink]="['/']">Connexion</a>
                <!-- <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/new-licence']" class="f-w-400">New licence</a></p> -->
              </div>
              
            </tr>
          </table>
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  </table>
</body>

