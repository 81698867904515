<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>A propos</h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<div class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>How we were founded</span>
                    <h2>Easy, fee-free banking for entrepreneurs</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p>Every month they moved their money the old way – which wasted their time and money. So they invented a beautifully simple workaround that became a billion-dollar business.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-footer-widget" >
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                        <p> Avec votre plateforme Trinity Change, consultez au quotidien les taux de change actualises, effectuer des simulations d'opérations puis reserver au besoin vos opérations pour les finaliser en agence.</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://web.facebook.com/TrinityFinances" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCVqrtGa73gJ4Ue3e9fIwvRg/" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Start Team Area -->

<!-- End Team Area -->

<!-- Start Ready To Talk Area -->
<div class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Prêt pour une discussion?</h3>
            <p>Notre équipe est disponible pour répondre a vos questions</p>
            <a routerLink="/" class="btn btn-primary">Contactez-nous</a>
            <span><a routerLink="/">Ou commencez dès maintenant avec un essai gratuit</a></span>
        </div>
    </div>
</div>
<!-- End Ready To Talk Area -->

<app-partner></app-partner>

<!-- Start App Download Area -->

<!-- End App Download Area -->

<!-- Start Account Create Area -->

<!-- End Account Create Area -->
