import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultComponent } from './demos/default/default.component';
import { FeedbackComponent } from './common/feedback/feedback.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { FunfactComponent } from './common/funfact/funfact.component';
import { AboutComponent } from './pages/about/about.component';
import { PartnerComponent } from './common/partner/partner.component';
import { FeaturesComponent } from './pages/features/features.component';
import { DownloadComponent } from './common/download/download.component';
import { AccountButtonComponent } from './common/account-button/account-button.component';
import { TalkButtonComponent } from './common/talk-button/talk-button.component';
import { TeamComponent } from './pages/team/team.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoFourComponent } from './demos/demo-four/demo-four.component';
import { DemoFiveComponent } from './demos/demo-five/demo-five.component';
import { DemoSixComponent } from './demos/demo-six/demo-six.component';
import { BlogRightSidebarComponent } from './pages/blog-right-sidebar/blog-right-sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TabViewModule} from 'primeng/tabview';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './shared/services/utils/localstorage.service';
import { ApiConfig } from './shared/api/app.config';
import { ApiCenter } from './shared/api/api.center';
import { TrinityRepository } from './shared/repositories/trinity.repository';
import { TrinityService } from './shared/services/business/trinity.service';
import { FormsModule } from '@angular/forms';
import { UtilsService } from './shared/services/utils/utils.service';
import { LimitToPipe } from './shared/pipes/limitto.pipe';
import { SharedModule } from './shared/shared.module';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { I18nServiceService } from './shared/services/utils/i18n-service.service';
import { AuthGuardService } from './shared/services/auth/auth-guard.service';
import { AuthService } from './shared/services/auth/auth.service';

import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import { ToastrModule } from 'ngx-toastr';
import { AccountValidationComponent } from './pages/account-validation/account-validation.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthVerificationComponent } from './pages/auth-verification/auth-verification.component';
import {ListboxModule} from 'primeng/listbox';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { CguComponent } from './pages/cgu/cgu.component';
import {InputNumberModule} from 'primeng/inputnumber';
import { PublicComponent } from './common/public/public.component';
import {DropdownModule} from 'primeng/dropdown';
import { FormatmoneyPipe } from './shared/pipes/formatmoney.pipe';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';


// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    FeedbackComponent,
    DemoTwoComponent,
    DemoThreeComponent,
    FunfactComponent,
    AboutComponent,
    PartnerComponent,
    FeaturesComponent, 
    DownloadComponent,
    AccountButtonComponent,
    TalkButtonComponent,
    TeamComponent,
    PricingComponent,
    NotFoundComponent,
    FaqComponent,
    BlogComponent,
    BlogDetailsComponent,
    SignupComponent,
    SigninComponent,
    ContactComponent,
    DemoFourComponent,
    DemoFiveComponent,
    DemoSixComponent,
    BlogRightSidebarComponent,
    AccountValidationComponent,
    DashboardComponent,
    AuthVerificationComponent,
    
    ModalComponent,
    CguComponent,
    PublicComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
    TabViewModule,
    FormsModule,ListboxModule,
    SharedModule,ToastModule,ButtonModule,RippleModule,NgbModule,InputNumberModule,DropdownModule,
    // TranslateModule.forRoot({
    //   defaultLanguage: 'en',
    //   loader: {
    //       provide: TranslateLoader,
    //       useFactory: HttpLoaderFactory,
    //       deps: [HttpClient]
    //   },
    //   isolate: true,
    // }),
    ToastrModule.forRoot(), // ToastrModule added

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    TrinityService,   //services
    TrinityRepository,  //repositories
    LimitToPipe, FormatmoneyPipe, //pipes
    ApiCenter, ApiConfig, LocalStorageService, UtilsService, I18nServiceService, AuthGuardService,AuthService,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
