<!-- Start Signup Area -->
<div class="signup-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-image">
                <img src="assets/img/signup-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="signup-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3> {{'ZONE_INSCRIPTION.txt_inscrire'| translate}} </h3>
                          <p >{{'ZONE_INSCRIPTION.txt_inscrire_deja'| translate}} <a routerLink="/connexion"> {{'ZONE_INSCRIPTION.txt_se_connecter'| translate}}</a></p> 
                          
                            <!-- <form>
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <input type="text" name="name" id="name" [(ngModel)]="user.nom" placeholder=" {{'ZONE_INSCRIPTION.txt_nom'| translate}}" class="form-control" maxlength="30">
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <input type="text" name="username" id="surname" [(ngModel)]="user.prenom" placeholder=" {{'ZONE_INSCRIPTION.txt_prenom'| translate}}" class="form-control" maxlength="">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group mb-3 col-12">
                                        <input type="text" name="telephone" id="telephone" [(ngModel)]="user.telephoneWhatsapp"  placeholder=" {{'ZONE_INSCRIPTION.txt_contact'| translate}}" class="form-control" maxlength="30">
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <p-dropdown [options]="countryList"  [(ngModel)]="selectedCountry" optionLabel="CountryName" [filter]="true" filterBy="CountryName"
                                            [showClear]="true" placeholder="{{'country'| translate}}">
                                                <ng-template pTemplate="selectedItem">
                                                <div class="country-item country-item-value" *ngIf="selectedCountry"> 
                                                    <div>{{selectedCountry.CountryName}} ffffff</div>
                                                </div>
                                            </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                <div class="country-item">
                                                    <div>{{country.CountryName}}</div>
                                                </div>
                                            </ng-template> 
                                        </p-dropdown>
                                    </div>
                                    
                                    
                                </div>
                                <div class="form-group mb-3">
                                    <input type="text" name="email" id="email" [(ngModel)]="user.email" placeholder=" {{'ZONE_INSCRIPTION.txt_email'| translate}}" class="form-control" maxlength="30">
                                </div>
                                
                                <div class=" form-group text-center mt-2 mb-2" *ngIf="chargement">
                                    <div class="container" style="padding-left: 100px; margin-left: 100px;"><div style="padding-left: 30px;margin-left: 30px;" class="loader"></div></div>
                                </div>
                                <button type="submit" class="btn btn-primary fa-solid fa-user" (click)="register()"><i class="fa-solid fa-user"></i> &nbsp; {{'ZONE_INSCRIPTION.btn_insrire'| translate}}</button>
                                <div class="connect-with-social">
                                    <button type="text" (click)="backTo()"><a class="fa-solid fa-arrow-left"  ><i class=""></i>&nbsp; {{'ZONE_INSCRIPTION.btn_retour'| translate}}</a></button>
                                </div>
                            </form> -->
                            
                            
                            
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <input type="text" name="name" id="name" [(ngModel)]="user.nom" placeholder=" {{'ZONE_INSCRIPTION.txt_nom'| translate}}" class="form-control" maxlength="30">
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <input type="text" name="username" id="surname" [(ngModel)]="user.prenom" placeholder=" {{'ZONE_INSCRIPTION.txt_prenom'| translate}}" class="form-control" maxlength="">
                                    </div>
                                </div>
                            
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <input type="text" name="telephone" id="telephone" [(ngModel)]="user.telephoneWhatsapp"  placeholder=" {{'ZONE_INSCRIPTION.txt_contact'| translate}}" class="form-control" maxlength="30">
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <p-dropdown [options]="countryList"  [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="name" [emptyFilterMessage]="message"
                                        [showClear]="true" placeholder="{{'country'| translate}}">
                                            <ng-template pTemplate="selectedItem">
                                            <div class="country-item country-item-value" *ngIf="selectedCountry"> 
                                                <div>{{selectedCountry.name}}</div>
                                            </div>
                                        </ng-template>
                                            <ng-template let-country pTemplate="item">
                                            <div class="country-item">
                                                <div>{{country.name}}</div>
                                            </div>
                                        </ng-template> 
                                    </p-dropdown>
                                </div>
                                
                                
                            </div>
                           

                                <div class="form-group mb-3">
                                    <input type="text" name="email" id="email" [(ngModel)]="user.email" placeholder=" {{'ZONE_INSCRIPTION.txt_email'| translate}}" class="form-control" maxlength="30">
                                </div>
                                
                                <div class=" form-group text-center mt-2 mb-2" *ngIf="chargement">
                                    <div class="container" style="padding-left: 100px; margin-left: 100px;"><div style="padding-left: 30px;margin-left: 30px;" class="loader"></div></div>
                                </div>

                            <form >
                                <button type="submit" class="btn btn-primary fa-solid fa-user" (click)="register()"><i class="fa-solid fa-user"></i> &nbsp; {{'ZONE_INSCRIPTION.btn_insrire'| translate}}</button>
                                <div class="connect-with-social">
                                    <button type="text" (click)="backTo()"><a class="fa-solid fa-arrow-left"  ><i class=""></i>&nbsp; {{'ZONE_INSCRIPTION.btn_retour'| translate}}</a></button>
                                </div>
                            </form> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area fa-arrow-right-to-bracket -->
