import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { I18nServiceService } from '../shared/services/utils/i18n-service.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../shared/services/utils/localstorage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    location: any;
    layoutClass: string;
    userConnected: any;
    showLoginLink : boolean = false;
    isMenuDisplayed: boolean = false;
    currentLang='fr';
    saveLang:any;
    
    languages=[
        {name:'FR',lang:'fr'},
        {name:'EN',lang:'en'}
    ];
    selected:any;
    options = [{name:'FR',lang:'fr'},
    {name:'EN',lang:'en'}]
    

    constructor(
        private translate: TranslateService,
        private localStorageservice: LocalStorageService,
        private i18nService: I18nServiceService,
        private router: Router,
        location: Location,
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.location = location.path();
                if(this.location == '/demo-3' || this.location == '/demo-6'){
                    this.layoutClass = 'navbar-style-two';
                } else {
                    this.layoutClass = '';
                }
            }
        });

        //translate.setDefaultLang('en');
        //translate.use('en');
        
    }

    // public selectLanguage(event: any) {
    //     this.translate.use(event.target.value);
    //   }

    ngOnInit() {
        //this.i18nService.localeEvent.subscribe(locale => this.translate.use(locale)); 
        this.userConnected = this.localStorageservice.getUserConnected();
        //console.log(this.userConnected)
    
        if (this.userConnected !== null && this.userConnected !== undefined && this.userConnected !== ""){
        this.showLoginLink = false; 
        } 
        else {
        this.showLoginLink = true;
        }
       this.saveLang = localStorage.getItem('lang');
       this.languages[0].name =this.saveLang;
       //console.log(this.languages[0].name)
       this.selected = this.options[0].name;
       if (this.saveLang === 'en'){
        this.options= this.languages.reverse();
        this.options[0].name = 'EN';
        this.options[1].name ='FR';
        this.selected = this.options[0].name;
       }
       this.selected=this.options[0].name;
    }

    // changeLocale(locale: string) {
    //     this.i18nService.changeLocale(locale);   
    // }
    onLangChange(currentLang:string){
        this.translate.use(currentLang);
        console.log(currentLang); 
        //this.localStorageservice.saveSelectedLanguage(currentLang);
        localStorage.setItem('lang',currentLang)
    }
    

}