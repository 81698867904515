
<!-- Start Main Banner Area -->


<div class="main-banner-section jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center mt-5 ">
                    <div class="col col-lg-2 col-md-12 bg-white text-center rounded"></div>
                    <div class="col col-lg-2 col-md-12 bg-white text-center rounded"></div>
                    <div class="col col-lg-2 col-md-12 bg-white text-center rounded"></div>
                    <div class="col-lg-6 col-md-12 mt-3">
                        <div class="banner-content" align="right">
                            <h1>{{'ZONE_ONE.txt_home'| translate}}</h1>
                            <a href="#comment_ca_marche" class="mb-2"></a>
                        </div>
                    </div>


                    <!-- <div class="col col-lg-4 col-md-12 bg-white text-center rounded">
                        <ul class="nav nav-pills mb-3 mt-2 nav-justified" id="pills-tab" role="tablist" (onChange)="handleTabIndexChange($event)">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                  </svg>{{'ZONE_ONE.txt_tab_buy'| translate}}
                            </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                                  </svg>
                                  {{'ZONE_ONE.txt_tab_sell'| translate}}</button>
                            </li>
                        </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                
                                <form >
                                    <div class="form-group mb-3">
                                        <label class="mb-1">{{'ZONE_ONE.lab1'| translate}} </label>
                                        <div class="money-transfer-field" align="center">
                                            <div class="amount-currency-select" align="center">
                                                <select class="nice-select text-white wid" (ngModelChange)="updateDeviseSelection($event)"  name="devise_achat" id="devise_achat_1" [(ngModel)]="selectedDeviseAchat">
                                                    <option *ngFor="let item of deviseList" [ngValue]="item" >{{item}}</option>
                                                </select>
                                            </div>
                                            <label class="mt-2 mb-2">{{'ZONE_ONE.lab3'| translate}}</label>
                                            
                                                <input type="number" (keyup)="onBuyingChangeEvent($event)"  class="form-control text-white wid" autocomplete="off"  name="buy" [(ngModel)]="amBuying" placeholder="Entrz le montant 0000">
                                           
                                        </div>
                                    </div>
                                    <div class="currency-info">
                                        <div class="bar"></div>
                                        <span>{{'ZONE_ONE.lab4'| translate}} <span style="display: none;"></span>{{'ZONE_ONE.lab5'| translate}} <br><strong> {{selectedLineTauxAchat || '-------'}} </strong></span>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="mb-1">{{'ZONE_ONE.lab2'| translate}}</label>
                                        <div class="money-transfer-field " align="center">
                                                <input type="text" class="form-control text-white wid" autocomplete="off" value="{{counterValueAchat}}" disabled>
                                            <div class="amount-currency-select " align="center"> 
                                                <div class=" simpl text-white wid mt-3" align="center" style="padding-top: 10px;"> XOF</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="money-transfer-info" (keyup)="onBuyingChangeEvent($event)"></div>
                                    <button type="submit" class="btn btn-primary" (click)="bookTransaction()">{{'ZONE_ONE.btn_tab_start'| translate}}</button>
                                    <div class="terms-info mt-2 mb-2">
                                        <p>{{'ZONE_ONE.tab_cond1'| translate}} <a routerLink="/#" routerLinkActive="active">{{'ZONE_ONE.tab_cond2'| translate}} </a></p>
                                    </div>
                                </form>

                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <form>
                                    <div class="form-group mb-3" >
                                        <label class="mb-1">{{'ZONE_ONE.lab1'| translate}} </label>
                                        
                                            
                                      <div class="money-transfer-field" align="center">
                                            
                                            <div class="amount-currency-select" align="center">
                                                <select class="nice-select text-white wid" (ngModelChange)="updateDeviseSelectionVente($event)"  name="devise_vente" id="devise_vente_2" [(ngModel)]="selectedDeviseVente">
                                                    <option  *ngFor="let item of deviseList" [ngValue]="item" >{{item}}</option>
                                                </select>
                                            </div> 
                                            <label class="mt-2 mb-2">{{'ZONE_ONE.lab3'| translate}}</label>
                                            <input type="number" (keyup)="onSellingChangeEvent($event)"  class="form-control wid text-white"  autocomplete="off" name="sell" [(ngModel)]="amSelling" placeholder="Entrez le montant 0000">
                                            
                                        </div> 
                                    </div> 
                                    <div class="currency-info">
                                        <div class="bar"></div>
                                        <span>{{'ZONE_ONE.lab4'| translate}} <span style="display: none;"></span> {{'ZONE_ONE.lab5'| translate}}  <br><strong>{{selectedLineTauxAchat || '-------'}} </strong></span>
                                    </div>
                                   
                                    <div class="form-group mb-3">
                                        <label>{{'ZONE_ONE.lab2'| translate}}</label>
                                        <div class="money-transfer-field" align="center">
                                            <input type="text" class="form-control text-white wid" autocomplete="off" value="{{counterValueVente}}">
                                            <div class="amount-currency-select" align="center">
                                                <div class=" simpl text-white wid mt-3" align="center" style="padding-top: 10px;"> XOF</div></div>
                                        </div>
                                    </div>
                                    <div class="money-transfer-info" >
                                      
                                    </div>
                                    <button type="submit" class="btn btn-primary" (click)="bookTransaction()">{{'ZONE_ONE.btn_tab_start'| translate}}</button>
                                    <div class="terms-info mt-2 mb-2">
                                        <p>{{'ZONE_ONE.tab_cond1'| translate}} <a routerLink="/#" routerLinkActive="active">{{'ZONE_ONE.tab_cond2'| translate}} </a></p>
                                    </div>
                                </form>
                            </div>
                          </div>
                    </div> -->
                    
                    <div class="featured-boxes-inner mt-2">
                        <div class="row m-0 justify-content-center">
                            <div class="text-center mb-2" *ngIf="isLoading">
                                     <br>
                                    <img src="assets/img/loader.gif" alt="" height="50px" width="50px"  srcset="">
                            </div>
                            
                            <div *ngFor="let item of boardRates" class="col-lg-2 col-sm-6 col-md-6 p-0">
                                <div class="single-featured-box align-items-center ca">
                                    <img src="{{item.flag}}" alt="" height="91px" width="134px">
                                    <h3 class="mt-4">{{item.nomDevise}}</h3>
                                    <p>{{'achat'| translate}}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down svg-bg" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                      </svg> <br> 1{{item.symbol}} = <strong class="achat">{{item.tauxMoyenAchat | limitTo:6 }} XOF</strong></p>
                                      
                                    <p>{{'vente'| translate}}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  class="bi bi-arrow-up svg-bg" viewBox="0 0 16 16" style="color:red ;">
                                        <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                                      </svg><br> 1{{item.symbol}} = <strong class="vente">{{item.tauxMoyenVente | limitTo:6 }} XOF</strong></p>
                                     

                                    
                                </div>
                            </div>
                            <div class="" align="center">
                                <a id="simulation" class="btn btn-primary mb-3" (click)="scrollPageDown()">{{'simuler'| translate}}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                  </svg>
                                </a>
                                <p id="heure-container" class="italic"> </p>
                              </div>
                              
                        </div>
                    </div>
                    
                   </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->



<!-- Start Comparisons Area Simulation -->
<div class="comparisons-area ptb-70 bg-f6f4f8" id="simulation">
  
    <div class="container" align="center">
        <div class="col bg-white">
                        <div class=" simulation bg-white mt-5 mb-2"> 
                            <h2  href="#simulation">Simulation</h2>
                            <p>Qu'il s'agisse d'un achat ou d'une vente de devise estimé la contrepartie de devise</p>
                        </div>
                        <ul class="nav nav-pills mb-3 mt-2 nav-justified" id="pills-tab" role="tablist" (onChange)="handleTabIndexChange($event)">
                            <li class="nav-item" role="presentation" tabindex="1">
                              <button class="nav-link active couleur buy-button  " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                  </svg>{{'ZONE_ONE.txt_tab_buy'| translate}}
                            </button>
                            </li>
                            <li class="nav-item" role="presentation" tabindex="2">
                              <button class="nav-link couleur sell-button " id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                                  </svg>
                                  {{'ZONE_ONE.txt_tab_sell'| translate}}</button>
                            </li>
                        </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                
                                
                                <form >
                                    <div class="form-group mb-3">
                                        <div class="money-transfer-field" >
                                            <div class="row testing ">
                                                
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center" >
                                                    <label class="mb-1">{{'ZONE_ONE.lab1'| translate}} </label>
                                                    <div class=" group1 mb-3" align="center">
                                                        <select class="nice-select text-white wid select1 large" (ngModelChange)="updateDeviseSelection($event)"  name="devise_achat" id="devise_achat_1" [(ngModel)]="selectedDeviseAchat" style="height: 50px;">
                                                            <option *ngFor="let item of deviseList" [ngValue]="item" >{{item}} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1">{{'ZONE_ONE.lab4'| translate}} {{'ZONE_ONE.lab5'| translate}}</label>
                                                    <input type="text"  class="form-control text-white large taux" value="{{selectedLineTauxAchat || '0.00'}}" disabled>
                                                </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1">{{'ZONE_ONE.lab3'| translate}} &nbsp;&nbsp;</label>

                                                    <p-inputNumber [(ngModel)]="amBuying" name="amBuying"  inputId="locale-fr" mode="decimal" locale="fr-FR" [minFractionDigits]="0" [maxFractionDigits]="3" [min]="0" [max]="10000000" >
                                                    </p-inputNumber>
                                               </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6 ml-2" align="center">
                                                    <label class="mb-1" style="visibility: hidden;">{{'ZONE_ONE.lab3'| translate}}</label>
                                                    <button type="submit" class="btn color" (click)="calculateBuyAmount()" align="center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator-fill" viewBox="0 0 16 16">
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z"/>
                                                      </svg> &nbsp;{{'calculer' | translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group mb-3 col-3">
                                        <label class="mb-1">{{'ZONE_ONE.lab2'| translate}}</label>
                                         <div class="money-transfer-field " align="center">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control text-white " autocomplete="off" value="{{counterValueBuy || '0' |formatmoney}}" disabled>
                                                <span class="input-group-text text-white input1" id="basic-addon2" style="background-color:#248abb; height: 50px;">FCFA</span>
                                              </div>
                                        </div> 
                                        
                                    </div>
                                    
                                    <div class="couleur">
                                        

                                        <hr class="bg-primary">
                                        <p class="text-white">{{'text' | translate}}</p>
                                        <p>Qu'il s'agisse d'un achat ou d'une vente de devise estimé la contrepartie de devise</p>

                                        <a routerLink="/inscription" class="btn btn-primary mb-2">{{'img_btn_sign' | translate}}</a>
                                    </div>
                                    
                                </form>

                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
   
                                <form >
                                    <div class="form-group mb-3">
                                        <div class="money-transfer-field" >
                                            <div class="row testing ">
                                                
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1">{{'ZONE_ONE.lab1'| translate}} </label>
                                                    <div class=" group1 mb-3" align="center">
                                                        <select class="nice-select text-white wid select1 large" (ngModelChange)="updateDeviseSelectionVente($event)"  name="devise_achat" id="devise_achat_1" [(ngModel)]="selectedDeviseVente" style="height: 50px;">
                                                            <option *ngFor="let item of deviseList" [ngValue]="item" >{{item}} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1">{{'ZONE_ONE.lab4'| translate}} {{'ZONE_ONE.lab6'| translate}}</label>
                                                    <input type="text"  class="form-control text-white large taux" value="{{selectedLineTauxVente || '0.00'}}" disabled>
                                                </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1">{{'ZONE_ONE.lab3.2'| translate}}</label>

                                                    <p-inputNumber [(ngModel)]="amSelling" name="amSelling" inputId="locale-fr" mode="decimal" locale="fr-FR" [minFractionDigits]="0" [maxFractionDigits]="3" [min]="0" [max]="10000000" >
                                                    </p-inputNumber>
                                                </div>
                                                <div class="form-group mb-3 col-lg-3 col-sm-6 col-md-6" align="center">
                                                    <label class="mb-1" style="visibility: hidden;">{{'ZONE_ONE.lab3'| translate}}</label>
                                                    <button type="submit" class="btn color" (click)="calculateSellAmount()" align="center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator-fill" viewBox="0 0 16 16">
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z"/>
                                                      </svg> &nbsp;{{'calculer' | translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group mb-3 col-3">
                                        <label class="mb-1">{{'ZONE_ONE.lab2'| translate}}</label>
                                         <div class="money-transfer-field " align="center">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control text-white " autocomplete="off" value="{{counterValueSell |formatmoney}}" disabled>
                                                <span class="input-group-text text-white input1" id="basic-addon2" style="background-color:#248abb; height: 50px;">{{selectedDeviseVente}}</span>
                                              </div>
                                        </div> 
                                        
                                    </div>
                                    
                                    <div class="nav-link active couleur">
                                        <hr class="bg-primary">
                                        <p class="text-white">{{'text' | translate}}</p>
                                    
                                        <a routerLink="/inscription" class="btn btn-primary mb-2">{{'img_btn_sign' | translate}}</a>
                                        
                                    
                                    </div>
                                    
                                </form>
                            
                            </div>
                          </div>
        </div>
    </div>
</div>
<!-- End Comparisons Area -->


<!-- Start Featured Boxes Area -->
<div class="featured-boxes-area mt-5 mb-5">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>
                        <h3>{{'ZONE_TWO.card_txt_taux_hd'| translate}}</h3>
                        <p>{{'ZONE_TWO.card_txt_taux_bd'| translate}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>
                        <h3>{{'ZONE_TWO.card_txt_simop_hd'| translate}}</h3>
                        <p>{{'ZONE_TWO.card_txt_simop_bd'| translate}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3>{{'ZONE_TWO.card_txt_serv_hd'| translate}}</h3>
                        <p>{{'ZONE_TWO.card_txt_serv_bd'| translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Featured Boxes Area -->

<!-- Start How It Works Area -->
<!-- <div class="how-it-works-area ptb-70" id="comment_ca_marche">
    <div class="container">
        <div class="section-title">
            <h2>{{'thd_zne_title'| translate}}</h2>
            <div class="bar"></div>
            <p> {{'thd_under_title'| translate}}</p>
        </div>

        <p style="text-align:center;">
        <iframe [src]='safeURL' align="middle" width="900" height="400" frameborder="0" allowfullscreen></iframe>
    </p>
</div>
</div> -->
<!-- End How It Works Area -->


<!-- Start Services Area -->
<div class="services-area ptb-70 pt-0">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">{{'titre' | translate}}</span>
                    <h2>{{'soustitre' | translate}}</h2>
                    <div class="bar"></div>
                    <p>{{'testsoustitre' | translate}}</p>

                    <a routerLink="/inscription" class="btn btn-primary">{{'img_btn_sign'| translate}}</a>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/5.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->


<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/7.jpg" alt="image" >
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">{{'fr_img_zne_t'| translate}}</span>
                    <h2>{{'fr_img_zne_title'| translate}}</h2>
                    <div class="bar"></div>
                    <p>{{'fr_img_zne_p'| translate}}.</p>

                    <a routerLink="/home" class="btn btn-primary">{{'taux_consult' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- End Services Area -->


<!-- Start Fun Facts Area -->
<app-funfact></app-funfact>
<!-- End Fun Facts Area -->

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>


<!-- Start Blog Area -->

<!-- End Blog Area -->

<!-- partenaire    -->
<app-partner></app-partner>
<!--  partenaire   -->

<!-- Start Account Create Area -->
<app-account-button></app-account-button>


<!-- End Account Create Area -->

