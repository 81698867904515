import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Users } from '../shared/models/users';
import { TrinityRepository } from '../shared/repositories/trinity.repository';
import { LocalStorageService } from '../shared/services/utils/localstorage.service';
import { UtilsService } from '../shared/services/utils/utils.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  //user : Users= new Users;
  userConnected:any;
  chargement : boolean = false;

  constructor(private utils:UtilsService,private router: Router,private trinityRepo: TrinityRepository,private localStorageService: LocalStorageService,) { }

  ngOnInit(): void {
  }


}
