import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCenter } from 'src/app/shared/api/api.center';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import { LocalStorageService } from 'src/app/shared/services/utils/localstorage.service';
import * as emailjs from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  phone_number: string;
  msg_subject: string;
  message: string;
  nameInvalid: boolean;

  nameTouched: boolean =false;
  emailInvalid: boolean;
  emailTouched: boolean =false;
  phone_numberInvalid: boolean;
  phone_numberTouched: boolean =false;
  msg_subjectInvalid: boolean;
  msg_subjectTouched: boolean =false;
  messageInvalid: boolean;
  messageTouched: boolean =false;

  emailSent: boolean = false;

  public register = {
    password: '',
    email: '',
  };

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private trinityRepo: TrinityRepository,
    private apiCenter: ApiCenter,
  ) { }

  ngOnInit() {
    this.ContactUs();

    this.nameTouched = false;
    this.emailTouched = false;
    this.phone_numberTouched = false;
    this.msg_subjectTouched = false;
    this.messageTouched = false;
  }

  async ContactUs() {
    // ...
  }

  
  sendEmail() {

    if (!this.name || !this.email || !this.phone_number || !this.msg_subject || !this.message) {
      alert("Tous les champs sont requis.");
      return;
    }
    // Vérifier si le formulaire est valide avant d'envoyer l'e-mail
    if (!this.isFormValid()) {
      return;
    }
    this.emailSent = true;

    // Remplacez 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID' et 'YOUR_USER_ID'
    // par les valeurs que vous obtenez après vous être inscrit sur EmailJS.
    const emailParams = {
      service_id: 'service_avoyzzi',
      template_id: 'template_b3ydufo',
      user_id: '2iatXqF7V0tO70g_U',
      template_params: {
        to_name: 'Trinity Finances',
        from_name :this.name, // Remplacez par le nom du destinataire si nécessaire
        message: this.message,
        reply_to:this.email,
        msg_subject:this.msg_subject ,
        phone_number:this.phone_number,
       
      }
    };

    const emailjsEndpoint = 'https://api.emailjs.com/api/v1.0/email/send';

    this.http.post(emailjsEndpoint, emailParams, { headers: { 'Content-Type': 'application/json' } }).subscribe(
      (response) => {
        console.log('E-mail envoyé avec succès !', response);
        // Réinitialiser le formulaire après l'envoi
        this.name = '';
        this.email = '';
        this.phone_number = '';
        this.msg_subject = '';
        this.message = '';
      },
      (error) => {
        console.error('Erreur lors de l\'envoi de l\'e-mail', error);
      }
    );
    this.resetForm();
  }

  isFormValid(): boolean {
    // Vérifier si tous les champs obligatoires sont remplis et si l'e-mail est valide
    return this.name && this.email && this.emailIsValid() && this.message && this.phone_numberIsValid();
    
  }

  emailIsValid(): boolean {
    // Vérifier la validité de l'adresse e-mail en utilisant une expression régulière
    const emailRegex = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$', 'i');
    return emailRegex.test(this.email);
  }

  phone_numberIsValid(): boolean {
    // Vérifier si le champ du numéro de téléphone contient uniquement des chiffres
    const phoneNumberRegex = new RegExp('^[0-9]+$');
    return phoneNumberRegex.test(this.phone_number);
  }

      onNameBlur() {
        this.nameInvalid = !this.name;
        this.nameTouched = true;
    }

    onEmailBlur() {
        this.emailInvalid = !this.email || !this.emailIsValid();
        this.emailTouched = true;
    }

    onPhoneBlur() {
        this.phone_numberInvalid = !this.phone_number || !this.phone_numberIsValid();
        this.phone_numberTouched = true;
    }

    onMsgSubjectBlur() {
        this.msg_subjectInvalid = !this.msg_subject;
        this.msg_subjectTouched = true;
    }

    onMessageBlur() {
        this.messageInvalid = !this.message;
        this.messageTouched = true;
    }

    resetForm() {
      const contactForm = document.getElementById('contactForm') as HTMLFormElement;
      if (contactForm) {
        contactForm.reset(); // Utilisation de la méthode reset() pour réinitialiser le formulaire
        this.emailSent = true; // Afficher le message de succès après la réinitialisation si nécessaire
    
        // Réinitialiser les variables associées aux messages d'erreur
        this.nameInvalid = false;
        this.emailInvalid = false;
        this.phone_numberInvalid = false;
        this.msg_subjectInvalid = false;
        this.messageInvalid = false;
    
        this.nameTouched = false;
        this.emailTouched = false;
        this.phone_numberTouched = false;
        this.msg_subjectTouched = false;
        this.messageTouched = false;
      }
    }
    
}
