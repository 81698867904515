<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/trinitynewlogo.png" height="108px" width="271px" alt="logo">  
                    <img src="assets/img/trinitynewlogo.png" height="108px" width="271px" alt="logo">
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div  class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a  routerLink="/home" routerLinkActive="active" class="nav-link">{{ 'MENU.1' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">{{ 'MENU.4' | translate }}</a>
                        </li>
                        <li  class="nav-item">
                            <a routerLink="/inscription" routerLinkActive="active" class="login-btn"><i class="flaticon-user">&nbsp;&nbsp;</i> {{ 'inscription' | translate }}</a>
                        </li>
                          <!-- <li class="nav-item">
                            <a routerLink="/public/consultation" routerLinkActive="active" class="nav-link">{{ 'MENU.2' | translate }}</a>
                        </li> -->
                    </ul>

                  
                </div>
                <!-- if connected showLoginLink -->
                <!-- <div *ngIf="!showLoginLink" class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/" routerLinkActive="active" class="nav-link">{{ 'home' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/public/features" routerLinkActive="active" class="nav-link">Consultation</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/public/pricing" routerLinkActive="active" class="nav-link">Réservation</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/public/dashboard" routerLinkActive="active" class="nav-link">Dashboard</a>
                        </li>
                    </ul>
                    <div class="others-options">
                          <div class="collapse navbar-collapse" id="navbar-list-4">
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg" width="40" height="40" class="rounded-circle">
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                  <a class="dropdown-item" href="#">Dashboard</a>
                                  <a class="dropdown-item" href="#">Log Out</a>
                                </div>
                              </li>   
                            </ul>
                          </div>
                        
                    </div>
                </div>  -->
                <div class="others">
                    
                    <!-- <select name='langChoose' #langChoose [(ngModel)]='currentLang' class="form-select form-select-sm" (change)="onLangChange(langChoose.value)">
                      <option *ngFor="let lang of languages"  value="{{lang.lang}}" [selected]="lang.name === selected">{{lang.name}}</option>
                    </select> -->

                    <select  name="selectOption" #selectOption id="selectOption" class="form-select form-select-sm" (change)="onLangChange(selectOption.value)">
                        <option *ngFor="let opt of options" value="{{opt.lang}}" [selected]="opt.name === selected"> {{opt.name}}</option>
                       
                    </select>
                
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
