// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../utils/localstorage.service';
@Injectable()
export class AuthService {
    public register = {
        password: '',
        email: '',
    }
    userConnected : any;
    showNextStep : boolean = false;
       
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
    ) {}
  // ...
  public isAuthenticated(): boolean {
    if(this.userConnected === null || this.userConnected === undefined  || this.userConnected === ""){
        this.router.navigate(["/login"]);
        
      }else{
        this.router.navigate(["/"]);
        
      }
      return !this.userConnected;
  }
    
}