import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';

@Component({
  selector: 'app-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss']
})
export class AccountValidationComponent implements OnInit {

  item:any;
  key:any;

  constructor(private trinityRepo: TrinityRepository,private route: ActivatedRoute,) { }


  ngOnInit(): void {
    this.key= this.trinityRepo.emailValidity(this.item)
    this.key = this.route.snapshot.params['param'];
    console.log(this.key);
    this.checkValidity();
  }

  //check if email is already validated
  checkValidity(){
    return !this.trinityRepo.emailValidity(this.item);
  }//end checkValidity


}
