// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  rateChangeInterval: 1800000,

  //production params
  itemsPerPage: 5,
  themeLogo: 'assets/images/icon/logo_temp.png',
  ressourceUrl: 'http://127.0.0.1:8000/api/admin/',
  //apiUrl: 'http://localhost:5000',
  apiUrl:'https://plateformapi.trinitycfxadmin.com',
  apifront: 'https://plateformapi.trinitycfxadmin.com/param/pays',
  STR_LIB_NOUVEAUTE: "Nouveautés",
  STR_LIB_AVENDRE: "A vendre",
  SAVE_PROJECT_INTERVAL: 300000000,
  configCoupuresRate: [
    {"nomDevise":"EUR","coupureAchat":"200","coupureVente":"5","flagImg":"assets/img/flag-eu.png","sign":"€"},
    {"nomDevise":"DOL","coupureAchat":"100","coupureVente":"100","flagImg":"assets/img/flag-usa.png","sign":"$"},
    {"nomDevise":"GBP","coupureAchat":"50","coupureVente":"5","flagImg":"assets/img/flag-uk.png","sign":"£"},
    {"nomDevise":"CHF","coupureAchat":"1000","coupureVente":"5","flagImg":"assets/img/flag-ch.png","sign":"CHF"},
    {"nomDevise":"XAF","coupureAchat":"1000","coupureVente":"1000","flagImg":"assets/img/flag-xaf.png","sign":"XAF"},
  ]
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
