<!-- Start Error Area -->
<div class="error-area">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/404.png" alt="error">
            <h3>Page Non trouvée</h3>
            <!-- <h3>Page Not Found</h3> -->
            <p>La page que vous recherchez n'est pas disponible. Son adresse a peut être changé.</p>
            <a href="/" class="btn btn-primary">Retour</a>

            <!-- <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a href="/" class="btn btn-primary">Go to Home</a> -->
        </div>
    </div>
</div>
<!-- End Error Area -->