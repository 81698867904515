import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

// Sentry.init({
//   dsn: "https://9b7d3c1e8e57415bbfb93fdd456936fa@o1304641.ingest.sentry.io/6737302",
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["localhost", "https://yourserver.io/api"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
