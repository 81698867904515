import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCenter } from 'src/app/shared/api/api.center';
import { TrinityRepository } from 'src/app/shared/repositories/trinity.repository';
import { LocalStorageService } from 'src/app/shared/services/utils/localstorage.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  public register = {
    password: '',
     email: '',
   }

  constructor(
    private http: HttpClient, private localStorageService: LocalStorageService,
     private router: Router,private trinityRepo: TrinityRepository, private apiCenter: ApiCenter, 
     ) { }

  ngOnInit() {
    this.getChangingRate()
  }
  

  async getChangingRate(){
    
    console.log(this.register);
    this.trinityRepo.getChangedRates(this.register).then((result) => { 

      
     
      if (result === undefined) {
        let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutesSS.";
        console.log(error)
      }
      if (result=== "error") {
        let error: string = result.message;
        console.log(error)
        return;
      } else {
        this.router.navigate(["/"]);
      }
    }, (error: any) => {
      let errorMsg: string = error.message;
      console.log(error)
      return;

    })
  }
}
