<!-- Start Login Area -->
<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>{{'ZONE_CONNEXION.txt_bienvenue'| translate}}</h3>
                            <p>{{'ZONE_CONNEXION.txt_trinity'| translate}}<a routerLink="/inscription">{{'ZONE_CONNEXION.txt_creer_cpte'| translate}}</a></p>
                            <form>
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" [(ngModel)]="user.email" placeholder="{{'ZONE_INSCRIPTION.txt_email'| translate}}" class="form-control">
                                </div>
                                <div class="form-group mb-3">
                                    <input type="password" name="password" id="password" [(ngModel)]="user.password" placeholder="{{'ZONE_INSCRIPTION.txt_pwd'| translate}}" class="form-control">
                                </div>
                                <div class=" form-group text-center mt-2 mb-2" *ngIf="chargement">
                                    <!-- <div class=""> sfqdzsdfc</div>
                                    <div style="padding-left: 20px;" class=" ml-10 loader text-secondary text-center" role="status">
                                    <span class="sr-only"></span>
                                    </div> -->
                                    <div class="container" style="padding-left: 100px; margin-left: 100px;"><div style="padding-left: 30px;margin-left: 30px;" class="loader"></div></div>
                                    
                                </div>
                                <button  class="btn btn-primary" (click)="login()">{{'ZONE_CONNEXION.btn_connexion'| translate}}</button>
                                <div class="connect-with-social">
                                    <button type="text" (click)="backTo()"><a class="fa-solid fa-arrow-left"  ><i class=""></i>&nbsp; {{'ZONE_INSCRIPTION.btn_retour'| translate}}</a></button>
                                </div>
                                <div class="forgot-password">
                                    <a routerLink="/ressert-password">{{'ZONE_CONNEXION.txt_mdp_oublie'| translate}}</a>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->
