import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

      transform(value: string, limit: number) : string {
        if(value === null || value === undefined) value = "";
        let trail = '...';
        return value.length > limit ? value.substring(0, limit) + trail : this.padR(value, limit, ' '); 
      }

      //padR
      padR(str: string, width: number, pad: string): string {
        if (!width || width < 1)
            return str;

        if (!pad) pad = " ";
        var length = width - str.length
        if (length < 1) str.substr(0, width);

        return (str + this.repeat(pad, length)).substr(0, width);
    }//end padR

    //padL
    padL(str: string, width: number, pad: string) {
        if (!width || width < 1)
            return str;

        if (!pad) pad = " ";
        var length = width - str.length
        if (length < 1) return str.substr(0, width);

        return (this.repeat(pad, length) + str).substr(0, width);
    }//end padL


      //repeat
      repeat(chr: string, count: number): string {
        var str = "";
        for (var x = 0; x < count; x++) { str += chr };
        return str;
    }//end repeat


    


}
