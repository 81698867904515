export class Taux {
    public   devise: string;
	public	achat: number;
    public vente:number;
	
	constructor() {

        this.devise = "";
		this.achat = 0;
        this.vente = 0;
		
	}
}